<template>
  <div class="RAKUMARTOrderValuePage">
    <el-collapse-transition>
      <footer style="display: flex" v-show="footerShow">
        <!-- 客户价格明细发票下载 -->
        <div class="right remarkbox">
          <div class="content">
            <div class="orderRemarks">
              <h3>{{ $fanyi('订单备注') }}：</h3>
              <textarea disabled v-model="$parent.datas.client_remark">
                      </textarea>
            </div>
            <div class="yReply">
              <h3>{{ $fanyi('负责人回复') }}：</h3>
              <textarea disabled v-model="$parent.datas.y_remark"> </textarea>
            </div>
          </div>


          <div class="xiazai" style="margin-top: 0px;">
            <el-button type="primary" @click="Detailsdownload">{{
        $fanyi('价格明细')
      }} <span style="font-weight: 700; font-size: 16px; margin-left: 5px;" class="el-icon-download"></span>
            </el-button>
          </div>
          <div class="tip-title">

            <div class="lefttip">
              1. [Cost per item]：The spreadsheet carefully figures out how much it costs to ship each item using
              different transportation methods to its final destination. <br>
              2. [More information]：In addition, it shows the size and weight of each item from the suppliers, making it
              easier to understand the products in the whole order.<br>
              3.You could click the button to download the spreedsheet.
            </div>
            <div class="rightimg">
              <img @click="dialogVisible = true" src="../../../../../../assets/index-img/fenxi.png" alt="">
            </div>
          </div>
        </div>
        <div class="totalCostInChina">
          <h3>
            {{ $fanyi('中国总成本') }}：
            <span class="allprice">${{ orderPriceGroup.sum_discount_price_dollar }}</span>
          </h3>
          <div>
            <label for="">{{ $fanyi('商品费用小计') }}：</label>
            <span style="color: #222;font-size: 14px;font-weight: 500;">
              ${{
        $fun.EURNumSegmentation(orderPriceGroup.goods_price_dollar)
      }}
              <span style="display: inline-block;margin-left: 10px;color: #222;font-size: 14px;font-weight: 500;">({{
          $fun.RMBNumSegmentation(orderPriceGroup.goods_price)
        }}￥)</span></span>
          </div>
          <div>

            <label for="">{{ $fanyi('佣金说明') }}（{{
        (Number(orderPriceGroup.service_rate) * 100).toFixed(1)
      }}%）：</label><span style="color: #222;font-size: 14px;font-weight: 500;">
              ${{
          $parent.CouponsData.ids === ''
            ? $fun.EURNumSegmentation(
              orderPriceGroup.old_service_rate_price_dollar
            )
            : $fun.EURNumSegmentation(
              orderPriceGroup.service_rate_price_dollar
            )
        }}
              <span style="display: inline-block;margin-left: 10px;color: #222;font-size: 14px;font-weight: 500;">({{
          $parent.CouponsData.ids === ''
            ? $fun.RMBNumSegmentation(
              orderPriceGroup.old_service_rate_price
            )
            : $fun.RMBNumSegmentation(
              orderPriceGroup.service_rate_price
            )
        }}￥)</span></span>
          </div>
          <div>
            <label for="">{{ $fanyi('附加服务费') }}：</label>
            <span><el-popover placement="bottom" trigger="click" popper-class="notHaveBackground">
                <div class="priceDetail">
                  <ul class="ohead">
                    <li class="optionName">{{ $fanyi('内容') }}</li>
                    <li>{{ $fanyi('数量') }}</li>
                    <li>$</li>
                    <li>￥</li>
                  </ul>
                  <ul class="obody" v-for="(item, index) in $parent.numberOfStores.useOption" :key="index">
                    <li class="optionName">{{ item.name_translate }}</li>
                    <li>{{ item.num }}</li>
                    <li>
                      {{
        $fun.RMBNumSegmentation(
          $fun.ceil(item.total * datas.exchange_rate)
        )
      }}
                    </li>
                    <li>{{ $fun.RMBNumSegmentation(item.total) }}</li>
                  </ul>
                </div>

              </el-popover>

              <span style="color: #222;font-size: 14px;font-weight: 500;">
                ${{
        $fun.EURNumSegmentation(orderPriceGroup.option_price_dollar)
      }}<span
                  style="display: inline-block;margin-left: 10px;color: #222;font-size: 14px;font-weight: 500;">({{
          $fun.RMBNumSegmentation(orderPriceGroup.option_price)
        }}￥)</span>
              </span>
            </span>
          </div>
          <div>
            <!--           {{-->
            <!--             $fun.EURNumSegmentation(-->
            <!--                 $fun.ceil(orderPriceGroup.others_price * datas.exchange_rate)-->
            <!--             )-->
            <!--           }}-->
            <!--           $({{-->
            <!--             $fun.RMBNumSegmentation(orderPriceGroup.others_price)-->
            <!--           }}￥)-->
            <label for="">{{ $fanyi('其他费用') }}：</label><span style="color: #222;font-size: 14px;font-weight: 500;">${{
        $fun.EURNumSegmentation(orderPriceGroup.others_price_dollar)
      }}<span style="display: inline-block;margin-left: 10px;color: #222;font-size: 14px;font-weight: 500;">({{
          $fun.RMBNumSegmentation(orderPriceGroup.others_price)
        }}￥)</span></span>
          </div>
          <div>
            <!--           {{-->
            <!--             $fun.EURNumSegmentation(-->
            <!--                 $fun.ceil(datas.chinese_amount_order.list.china_freight_amount)-->
            <!--             )-->
            <!--           }}-->
            <!--           $({{-->
            <!--             $fun.RMBNumSegmentation(-->
            <!--                 $fun.ceil(-->
            <!--                     datas.chinese_amount_order.list.china_freight_amount /-->
            <!--                     datas.exchange_rate-->
            <!--                 )-->
            <!--             )-->
            <!--           }}￥)-->
            <label for="">{{ $fanyi('中国国内运费') }}：</label><span style="color: #222;font-size: 14px;font-weight: 500;">${{
        $fun.EURNumSegmentation(orderPriceGroup.freight_dollar)
              }}<span style="display: inline-block;margin-left: 10px;color: #222;font-size: 14px;font-weight: 500;">({{
                $fun.RMBNumSegmentation(orderPriceGroup.freight)
                }}￥)</span></span>
          </div>
          <div>
            <label for="">{{ $fanyi('汇率') }}：</label><span style="color: #222;font-size: 14px;font-weight: 500;">${{
              $store.state.exchangeRate }}≈1￥</span>
          </div>


        </div>
      </footer>
    </el-collapse-transition>
    <el-dialog :visible.sync="dialogVisible" width="80%">

      <div style="padding: 40px;">
        <img style="width: 100%;height: 100%;" src="../../../../../../assets/index-img/fenxi.png" alt="">
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Europess from '@/api/wangZhi'
let Europe = Europess + '/client/'
export default {
  name: 'RAKUMARTOrderValue',
  data() {
    return {
      img: require('../../../../../../assets/pulldown_0.svg'),
      footerShow: true,
      dialogVisible: false
    }
  },
  computed: {
    datas() {
      return this.$parent.datas
    },
    orderPriceGroup() {
      return this.$parent.orderPriceGroup
    },
  },
  methods: {
    // 明细下载
    Detailsdownload() {
      let params = {
        order_sn: this.$route.query.order_sn,
      }
      // 获取token 添加headers发送请求
      let user_token = localStorage.getItem('user_token')
      this.$parent.fullscreenLoading = true
      // 
      axios
        .post(Europe + 'download.userInvoiceDownload', params, {
          responseType: 'blob', // 1.首先设置responseType对象格式为 blob:
          headers: {
            ClientToken: `Bearer ${user_token}`
          }
        },
        )
        .then((res) => {
          this.$parent.fullscreenLoading = false
          if (res.size < 200) {
            return this.$message.warning(this.$fanyi('操作失败'))
          }
          let blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          })
          // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let url = window.URL.createObjectURL(blob) // 3.创建一个临时的url指向blob对象

          // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement('a')
          a.href = url
          a.download = 'Product cost' + this.$route.query.order_sn + '.xls'
          a.click()
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(url)
          this.diaShow = !this.diaShow
        })
        .catch((error) => {
          this.$parent.fullscreenLoading = false
          this.$message(error.message)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.priceDetail {
  min-height: 74px;
  background-color: white;
  z-index: 2;

  >ul {
    display: flex;
    border-radius: 0px;

    >li {
      height: 37px;
      background: #fafafa;
      width: 80px;
      text-align: center;
      height: 37px;
      line-height: 37px;
      border: solid 1px #ededed;
      margin: 0 -1px -1px 0;
      font-size: 12px;
      color: #000000;

      &.optionName {
        width: 240px;
      }
    }

    &.obody {
      li {
        background-color: white;

        &.optionName {
          background: #fafafa;
          width: 240px;
        }
      }
    }
  }
}



/deep/ .el-button {
  width: 120px !important;
  height: 40px;
  background: #ff730b;
  border-radius: 4px;
}

/deep/ .el-dialog__close {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.RAKUMARTOrderValuePage {
  margin-top: 30px !important;
  // padding: 30px 40px 0px 40px;
  // background: #f9f9f9;
  width: 1400px;
  // background: #ffffff;
  // border-radius: 6px;
  // border: 1px solid #e2e2e2;

  margin: 0 auto;

  .flexAndCenter {
    display: flex;
    align-items: center;
  }

  header {
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px dashed #e2e2e2;

    div:first-child {
      font-weight: bold;
      color: #222222;
    }

    div:last-child {
      width: 24px;
      height: 24px;

      justify-content: center;
      color: #fff;
      border-radius: 50%;
    }

    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  footer {
    margin-top: 30px;
    // padding-left: 10px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;

    .totalCostInChina {
      width: 400px;
      // margin-right: 120px;
      width: 480px;
      height: 506px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      padding: 30px;

      h3 {
        font-weight: 600;
        color: #222222;
        font-size: 18px;
        font-size: 18px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        display: flex;
        font-weight: 600;
        justify-content: space-between;
        align-items: center;
        color: #222222;
        margin-bottom: 10px;

        span {
          font-size: 18px;
          font-family: Roboto-Black, Roboto;
          font-weight: 900;
          color: #ff730b;
        }
      }

      >div {
        width: 400px;
        margin-bottom: 5px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        height: 55px;
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        border-bottom: 1px solid #e2e2e2;

        label {
          display: inline-block;
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #666666;
        }

        .fujiafuwu {
          text-decoration: underline;
          color: #608bff;
          cursor: pointer;
          font-size: 14px;
        }

        span {
          font-size: 12px;
        }
      }
    }

    .right {
      width: 890px;
      height: 506px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      padding: 30px;
      padding-bottom: 0px;

      h3 {
        font-weight: 600;
        color: #222222;
        font-size: 18px;
        margin-bottom: 20px;
      }

      textarea {
        width: 830px;
        height: 160px;
        background: #f9f9f9;
        border-radius: 6px;
        border: 1px solid #e2e2e2;
        border-radius: 6px;

        color: #222222;
        padding: 10px;
        margin-bottom: 30px;
      }
    }

    .remarkbox {


      .content {
        display: flex;
        justify-content: space-between;

        textarea {
          width: 400px;
          height: 160px;
          background: #f9f9f9;
          border-radius: 6px;
          border: 1px solid #e2e2e2;
          border-radius: 6px;

          color: #222222;
          padding: 10px;
          margin-bottom: 10px;
        }
      }

      .tip-title {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 20px;

        .lefttip {
          flex: 2;
          line-height: 26px;
          color: #999;
        }

        .rightimg {
          flex: 1;
          margin-left: 30px;

          img {
            width: 100%;
            height: 88%;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }




    }
  }
}

.xiazai {
  margin-top: 8px;
  text-align: left;
  border: none !important;

  button {
    min-width: 280px;
    height: 44px;
    background: #fff2e8;
    border-radius: 4px;
    border: 1px solid #ff730b;

    color: #ff730b;

    border: 1px solid #ff730b;
  }
}
</style>
