<template>
  <div element-loading-text="" element-loading-background="rgba(0, 0, 0, 0.3)" style="padding-bottom: 230px"
    class="orderPage" v-loading.fullscreen.lock="fullscreenLoading" v-if="!placingSuccessfully">
    <whyDiaVue ref="whyDia"></whyDiaVue>
    <HomePageTop ref="tyop"></HomePageTop>

    <!-- 表格 待支付 订单待支付-->
    <div class="main zhu base-w dingDanXiangQing" :class="{ AllGoods: showAllGoods }"
      v-if="['temporary', 'editing', 'offer'].indexOf(orderStatus) == -1">
      <!-- 头部 -->
      <div class="head">
        <span class="order_snBox">{{ $fanyi('订单号') }} ：{{ datas.order_sn }}</span>
        <span class="time">
          <font v-if="['temporary'].indexOf(orderStatus) == -1" style="font-size: 14px !important">
            {{ $fanyi('时间') }} ：{{ datas.created_at }}
          </font>
          <button v-if="isedit" class="editOrder" @click="
      oldobligation = orderStatus
    orderStatus = 'editing'
    dataProcessing()
      ">
            {{ $fanyi('修改订单') }}
          </button>
          <button v-if="['warehouse'].indexOf(orderStatus) !== -1" class="addshopLibrary" @click="addLibraryOfGoods">
            {{ $fanyi('加入商品库') }}
          </button>
        </span>
      </div>
      <!-- 订单列表 -->
      <div class="order">
        <ul>
          <li class="inputBox"
            :style="['obligation', 'OrderDetails', 'purchased', 'shipping'].indexOf(orderStatus) != -1 ? 'flex: 0 0 186px' : ''"
            @click="selectAllLi">
            <input class="headCheckBox" type="checkbox" name="" id="" v-model="checked" @change="allChoice" />
            {{ $fanyi('全选') }}
          </li>
          <li class="goods">{{ $fanyi('产品') }}</li>
          <li class="detail">{{ $fanyi('属性') }}</li>
          <li class="num">
            <span
              :style="['obligation', 'OrderDetails', 'purchased', 'shipping'].indexOf(orderStatus) != -1 ? 'margin-left: 20px' : ''">
              {{ $fanyi('单价') }}
            </span>
          </li>
          <!-- 付款后调查在库数量 -->
          <li class="price" v-if="['purchased', 'purchase'].indexOf(orderStatus) != -1">
            {{ $fanyi('调查在库数量') }}
          </li>
          <li class="num" v-if="[
      'obligation',
      'offer',
      'delete',
      'editing',
      'temporary',
      'cancel',
    ].indexOf(orderStatus) != -1">
            {{ $fanyi('购买数量') }}
          </li>
          <li class="num" v-else>
            {{ $fanyi('实际购买数量') }}
          </li>
          <!-- 付款前调查在库树 -->
          <li class="price" v-if="['obligation', 'OrderDetails', 'shipping'].indexOf(orderStatus) != -1">
            {{ $fanyi('调查在库数量') }}
          </li>
          <li class="zhongGuoSuoYouFeiYong" v-if="['offer', 'temporary'].indexOf(orderStatus) != -1">
            <div title="OPTION">
              {{ $fanyi('附加服务') }}
            </div>
          </li>

          <li class="zhongGuoSuoYouFeiYong head" v-if="[
      'obligation',
      'offer',
      'temporary',
      'shipping',
      'purchased',
      'warehouse',
      'purchase',
      'delete',
      'cancel',
    ].indexOf(orderStatus) != -1
      ">
            <div :title="$fanyi('中国国内所有费用')">
              {{ $fanyi('中国国内所有费用') }}
            </div>
          </li>

          <li class="zhongGuoSuoYouFeiYong head" v-else>
            <div :title="$fanyi('资金变动明细')">
              {{ $fanyi('资金变动明细') }}
            </div>
          </li>
          <li class="zhongGuoSuoYouFeiYong head" v-if="orderStatus == 'purchase'">
            <div :title="$fanyi('资金变动明细')">
              {{ $fanyi('资金变动明细') }}
            </div>
          </li>
          <li class="zhongGuoSuoYouFeiYong head" v-if="[
      'shipping',
      'purchased',
      'warehouse',
      'cancel',
      'delete',
    ].indexOf(orderStatus) != -1">
            <div :title="$fanyi('资金变动明细')">
              {{ $fanyi('资金变动明细') }}
            </div>
          </li>

          <li class="zhongGuoSuoYouFeiYong head" v-if="['obligation'].indexOf(orderStatus) != -1">
            <div :title="$fanyi('预估总费用')">
              {{ $fanyi('预估总费用') }}
            </div>
          </li>
          <li class="zhongGuoSuoYouFeiYong head" v-if="['obligation'].indexOf(orderStatus) != -1">
            <div :title="$fanyi('预计进口产品费用明细')">
              {{ $fanyi('预计进口产品费用明细') }}
            </div>
          </li>
          <li v-if="['offer', 'temporary'].indexOf(orderStatus) != -1" class="constDetail">
            {{ $fanyi('备注') }}
          </li>
          <li v-if="[
      'purchase',
      'purchased',
      'delete',
      'editing',
      'warehouse',
      'shelves',
      'shipping',
      'cancel',
    ].indexOf(orderStatus) != -1" class="constDetail">
            {{ $fanyi('商品状态') }}
          </li>
        </ul>
        <!-- 店铺 -->
        <div class="goodsBox" :style="!showAllGoods ? 'height:200px' : ''">
          <div v-for="(aitem, aindex) in newList" :key="aindex">
            <h1>
              <div class="checkedBox" @click="shopAllSelect(aitem, aindex)">
                <input type="checkbox" name="" id="" v-model="aitem.checked" @change="shopAll(aindex)" />
              </div>
              {{ $fanyi('店铺') }}：{{ aitem.shop_name }}
              <span class="img1688box">
                <img src="../../../../../assets//al.svg" alt="" />
              </span>
            </h1>
            <!-- {{ aitem.list 详情 }} -->
            <div class="goodsCon xiangqingCon">
              <div v-for="item in aitem.list" :key="item.id">
                <ul class="goodsDetailCon" :class="{
      bordercolor:
        item.confirm_num == 0 &&
        item.order_detail_replace.length == 0 &&
        orderStatus == 'obligation',
      borderblue:
        item.order_detail_replace.length > 0 &&
        orderStatus == 'obligation',
    }">
                  <!-- 全选 -->
                  <li class="inputBox" @click=" item.checked = !item.checked; radio(aindex)">
                    <input type="checkbox" name="" id="" @change="radio(aindex)" v-model="item.checked" />
                    <!-- 商品番号 -->
                    <span class="rowNumber">
                      {{ item.sorting }}
                    </span>
                    <!--商品 图片 -->

                    <el-popover placement="right" trigger="hover">
                      <img :src="item.pic" alt="" style="width: 300px; height: 300px" />
                      <img @click="clickShopImg(item)" :src="item.pic" alt="" slot="reference" />
                    </el-popover>
                  </li>
                  <!-- 产品-->
                  <li class="goods">
                    <el-popover placement="bottom" width="400" trigger="hover">
                      {{ item.goods_title }}
                      <div class="showGoodsTitle" :title="item.goods_title" slot="reference"
                        @click="clickGoodsTitle(item)">
                        <font>{{ item.goods_title }}</font>
                      </div>
                    </el-popover>
                  </li>

                  <!-- 属性 -->
                  <li class="detail" :class="item.detailWindow == true ? 'active goodsDetail' : 'goodsDetail'">
                    <el-popover placement="bottom" trigger="hover">
                      <div class="goodsDetailAll">
                        <div class="goodsDetailAllOneBox" v-for="(detailItem, detailIndex) in item.detail"
                          :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                          <h1 class="detailTitle">
                            {{ detailItem.key }}
                          </h1>
                          <p>
                            <span class="detailBody">
                              {{ detailItem.value }}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div slot="reference" class="showDetail"
                        v-if="['temporary', 'editing'].indexOf(orderStatus) == -1">
                        <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                          :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay"
                          @click.stop="clickGoodsSpec(item)">
                          {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                        </p>
                        <img @click.stop="clickGoodsSpec(item)"
                          v-if="['temporary', 'editing'].indexOf(orderStatus) != -1"
                          src="../../../../../assets/preview_order/baianji.svg" alt="" />
                      </div>
                    </el-popover>
                    <el-popover placement="right" trigger="click">
                      <div class="detailWindowBody">
                        <div class="detailOpt" v-for="(goodsGuiGeItem, goodsGuiGeIndex) in item.pageSpecification"
                          :key="goodsGuiGeIndex">
                          <h1>{{ goodsGuiGeItem.key }}</h1>
                          <div class="optClass">
                            <span v-for="(actGuige, actIndex) in goodsGuiGeItem.value" :key="actIndex"
                              :title="actGuige.name" @click="clickGoodsGuiGe(item, actGuige, actIndex, goodsGuiGeIndex)"
                              :class="actIndex == goodsGuiGeItem.active ? 'active' : ''">
                              {{ actGuige.name }}
                            </span>
                          </div>
                        </div>

                      </div>
                      <!-- 可选择 -->
                      <div slot="reference" class="showDetail"
                        v-if="['temporary', 'editing'].indexOf(orderStatus) != -1">
                        <div class="showDetailCon">
                          <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                            :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay">
                            {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                          </p>
                        </div>
                        <img @click.stop="clickGoodsSpec(item)"
                          v-if="['temporary', 'editing'].indexOf(orderStatus) != -1"
                          src="../../../../../assets/preview_order/baianji.svg" alt="" />
                      </div>
                    </el-popover>
                  </li>
                  <!-- 单价 -->
                  <li class="price">
                    ${{ $fun.EURNumSegmentation($fun.ceil((item.confirm_price && item.confirm_price > 0 ?
      item.confirm_price : item.price) * datas.exchange_rate)) }}
                    <span style="display: inline-block; margin-left: 10px">
                      ({{ $fun.RMBNumSegmentation(item.confirm_price && item.confirm_price > 0 ? item.confirm_price :
      item.price) }}￥)
                    </span>
                  </li>
                  <!-- 付款后调查在库数量 -->
                  <li class="num" v-if="['purchased', 'purchase'].indexOf(orderStatus) != -1">
                    <div>
                      <div style="font-weight: 900">
                        {{ item.confirm_num }}
                      </div>
                    </div>
                  </li>
                  <!-- 购买数量 -->
                  <li class="num">
                    <el-input-number v-if="['temporary', 'editing'].indexOf(orderStatus) != -1"
                      v-model="item.confirm_num" @change="getTotalPrice(item)" :step="1" :step-strictly="true"
                      size="mini" :min="0">
                    </el-input-number>
                    <span v-else-if="[
      'obligation',
      'offer',
      'delete',
      'editing',
      'cancel',
      'temporary',
    ].indexOf(orderStatus) == -1
      " style="font-weight: 900; margin-bottom: 20px !important">
                      {{ item.purchase_num ? item.purchase_num : '--------' }}
                      <br />
                    </span>
                    <span v-else style="font-weight: 900">
                      {{ item.num }} <br />
                    </span>
                  </li>
                  <!-- 付款前调查在库数量 -->
                  <li class="num" v-if="['obligation', 'OrderDetails', 'shipping'].indexOf(orderStatus) != -1">
                    <div>
                      <div style="font-weight: 900">
                        {{ item.confirm_num }}
                      </div>
                    </div>
                  </li>
                  <!--                  OPTION-->
                  <li class="zhongGuoSuoYouFeiYong" v-if="['offer', 'temporary'].indexOf(orderStatus) != -1">
                    <div class="Opt">
                      <!-- {{item.option  }} -->
                      <p class="list" v-for="(eeitem, index) in item.option" :key="index">
                        <span v-if="eeitem.num > 0">
                          {{ eeitem.tag }}&nbsp;{{ eeitem.name_translate }}*{{ eeitem.num }}
                        </span>
                      </p>
                    </div>
                  </li>
                  <!--             中国所有费用-->
                  <li class="yuGuDaoShouFeiYong body1" v-if="orderStatus == 'obligation'
      ? [
        'obligation',
        'purchase',
        'purchased',
        'delete',
        'editing',
        'warehouse',
        'shelves',

        'shipping',
      ].indexOf(orderStatus) != -1 &&
      item.order_detail_replace.length == 0
      : [
        'obligation',
        'purchase',
        'purchased',
        'delete',
        'editing',
        'warehouse',
        'shelves',
        'cancel',
        'shipping',
      ].indexOf(orderStatus) != -1
      ">
                    <div>
                      <span style="font-weight: 700">
                        ${{ $fun.EURNumSegmentation(item.china_amount.sum_amount) }}

                      </span>
                      <el-popover placement="bottom" trigger="click">
                        <div class="yuGuDaoShouFeiYongMingXi yugu">
                          <p>
                            <span class="title">
                              {{ $fanyi('产品费用') }}：
                            </span>
                            <span class="content">
                              ${{ $fun.EURNumSegmentation(item.china_amount.list.goods_amount) }}
                            </span>
                          </p>
                          <p>
                            <span class="title">
                              {{ $fanyi('佣金') }}：
                            </span>
                            <span class="content" style="font-weight: 700">
                              ${{ $fun.EURNumSegmentation(item.china_amount.list.service_amount) }}
                            </span>

                          </p>
                          <p>
                            <span class="title">
                              {{ $fanyi('附加服务') }}：
                            </span>
                            <span class="content" style="font-weight: 700">
                              ${{ $fun.EURNumSegmentation(item.china_amount.list.option_amount) }}
                            </span>

                          </p>
                          <p>
                            <span class="title">
                              {{ $fanyi('中国物流费用') }}：
                            </span>
                            <span class="content">
                              ${{ $fun.EURNumSegmentation(item.china_amount.list.china_freight_amount) }}
                            </span>
                          </p>
                        </div>
                        <span class="mingXi" slot="reference">
                          {{ $fanyi('明细') }}
                          <div class="el-icon-arrow-down" style="display: inline-block; font-size: 13px"></div>
                        </span>
                      </el-popover>
                    </div>
                  </li>
                  <!-- 预估总费用 -->
                  <li class="yuGuDaoShouFeiYong body1" v-if="orderStatus == 'obligation'
      ? [
        'obligation',
        'purchase',
        'purchased',
        'delete',
        'editing',
        'warehouse',
        'shelves',

        'shipping',
      ].indexOf(orderStatus) != -1 &&
      item.order_detail_replace.length == 0
      : ['obligation'].indexOf(orderStatus) != -1
      ">
                    <div>
                      <span style="font-weight: 700">${{
      $fun.EURNumSegmentation(
        item.client_buy_amount[ind].sum_amount
      )
    }}
                      </span>
                      <el-popover placement="bottom" trigger="click">
                        <div class="yuGuDaoShouFeiYongMingXi yugu">
                          <p>
                            <span class="title" style="text-align: right">
                              {{ $fanyi('产品成本') }}:
                            </span>
                            <span class="content">
                              ${{ $fun.EURNumSegmentation(item.client_buy_amount[ind].list.china_amount) }}
                            </span>

                          </p>
                          <p>
                            <span class="title">
                              {{ $fanyi('预估国际运费') }}:
                            </span>
                            <span class="content" style="font-weight: 700">
                              ${{ $fun.EURNumSegmentation(item.client_buy_amount[ind].list.international_freight_amount)
                              }}
                            </span>
                          </p>
                          <p>
                            <span class="title">
                              {{ $fanyi('预估美国国内运输') }}:
                            </span>
                            <span class="content" style="font-weight: 700">
                              ${{ $fun.EURNumSegmentation(item.client_buy_amount[ind].list.client_internal_amount) }}
                            </span>

                          </p>
                        </div>
                        <span class="mingXi" slot="reference">
                          {{ $fanyi('明细') }}
                          <div class="el-icon-arrow-down" style="display: inline-block; font-size: 13px"></div>
                        </span>
                      </el-popover>
                    </div>
                  </li>

                  <!-- 资金变动明细 -->
                  <li class="yuGuDaoShouFeiYong body1" v-if="[
      'purchased',
      'purchase',
      'shelves',
      'warehouse',
      'shipping',
      'delete',
      'cancel',
      ,
    ].indexOf(orderStatus) != -1
      ">
                    <div>
                      <span>${{ item.change_price }}</span>
                      <span class="mingXi warehouse" @click="
      goodsPriceUpdateDetailDialogVisible = true
    getdetails(item.id)
      ">
                        {{ $fanyi('明细') }}
                        <div class="el-icon-arrow-down" style="display: inline-block; font-size: 14px"></div>
                      </span>
                    </div>
                  </li>

                  <!-- 预计产品进口详情 -->
                  <li class="viewdetails" v-if="orderStatus == 'obligation' && item.order_detail_replace.length == 0">
                    <div>
                      <p @click="showverdetalles(item.id)">
                        View details
                        <span class="el-icon-arrow-right"></span>
                      </p>
                    </div>
                  </li>
                  <!-- 查看商品详情 -->
                  <li v-if="item.order_detail_replace.length > 0 && orderStatus == 'obligation'" style="flex: 1.5">
                    <div style="margin-right: -100px" class="ViewProducts" @click="clickGoodsDetails(item)">
                      {{ $fanyi('产品详情') }}
                    </div>
                  </li>
                  <li v-if="item.order_detail_replace.length > 0 && orderStatus == 'obligation'" style="flex: 1.5">
                    <div class="Replacementproduct">
                      <SustituciónDeBienes :goodsToBeReplaced="item" :shopName="aitem.shop_name"
                        :SustituciónDeBienes="item.order_detail_replace" v-if="item.order_detail_replace.length > 0 &&
      $route.query.type !== 'shipping'
      "></SustituciónDeBienes>
                    </div>
                  </li>
                  <!--  -->
                  <!-- 状态 -->
                  <li class="constDetail" style="
                      height: 70px;
                      line-height: 70px;
                      text-align: center;
                      margin-top: 0;
                      padding-top: 0;
                    " v-if="[
      'purchased',
      'purchase',
      'shelves',
      'warehouse',
      'shipping',
      'cancel',
      'delete',
    ].indexOf(orderStatus) != -1
      ">
                    <div>
                      {{ status_name ? $fanyi(status_name) : '---' }}</div>
                  </li>
                  <li v-if="item.confirm_num == 0 && orderStatus == 'obligation'" class="dingwei">
                    Product unavailable
                  </li>
                  <li v-if="item.order_detail_replace.length > 0 &&
      orderStatus == 'obligation'
      " class="dingwei">
                    Product unavailable
                  </li>
                </ul>
                <!--  附加服务备注 -->
                <div class="goodsDetailOrder">
                  <!-- 附加服务 -->
                  <div class="service" v-show="item.option.length > 0 && item.option.some((i) => i.num != 0)"
                    v-if="['temporary', 'offer'].indexOf(orderStatus) == -1">
                    <!-- 'obligation' -->
                    <span class="AdditionalserviceTitle">
                      {{ $fanyi('Option') }}:
                    </span>
                    <!-- option区域 -->
                    <div class="opti" :class="{
      Additionalservice: item.type == true,
      Additional: item.type == false,
    }">
                      <p class="item" v-for="(optitem, index) in item.option
      .filter((i) => i.num > 0)
      .slice(0, item.len)" :key="index">
                        {{ optitem.tag }}&nbsp; &nbsp;{{
      optitem.name_translate
    }}*{{ optitem.num }}
                        <span v-if="item.len == 1 &&
      item.option.filter((i) => i.num > 0).length > 1
      ">......</span>
                      </p>
                    </div>
                    <div v-if="item.option.filter((i) => i.num > 0).length > 1" class="all" @click="
      item.type = !item.type
    all(item)
      ">
                      View all <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>

                  <!-- 备注 -->
                  <div class="remarkCon" v-show="item.client_remark">
                    <span class="remarkTitle">{{ $fanyi('备注') }}：</span>
                    <div class="remarkBody">{{ item.client_remark }}</div>
                  </div>
                  <!-- 业务给客户的回复 -->
                  <div class="huiFu" v-show="item.y_reply">
                    <span class="huiTitle">{{ $fanyi('工作人员回复') }}：</span>
                    <div class="huiBody">{{ item.y_reply }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 打开和收起列表 -->
      <div class="showAllGoods" v-if="true">
        <span></span>
        <div style="display: flex; justify-content: center; align-items: center">
          <span v-if="!showAllGoods" style="margin-right: 10px;">Open</span>
          <span v-else style="margin-right: 10px;">Put away</span>
          <img class="jiahaoIcon" v-if="!showAllGoods" src="../../../../../assets/icon/putdown.png"
            @click="showAllGoods = !showAllGoods" />
          <img class="jiahaoIcon" v-else src="../../../../../assets/icon/putdown1.png"
            @click="showAllGoods = !showAllGoods" />
        </div>
      </div>
    </div>
    <!--代提出 提出订单-->
    <div class="main zhu base-w submit_box" v-else :class="{ AllGoods: showAllGoods }">
      <!-- 头部 -->
      <div class="head">
        <span class="order_snBox">
          {{ $fanyi('订单号') }} ：{{ datas.order_sn }}
        </span>
        <span>
          <font v-if="['temporary'].indexOf(orderStatus) == -1">
            {{ $fanyi('时间') }} ：{{ datas.created_at }}
          </font>
          <button v-if="isedit" v-show="['edting'].indexOf(orderStatus) != -1" class="editOrder" @click="
      oldobligation = orderStatus
    orderStatus = 'editing'
    dataProcessing()
      ">
            {{ $fanyi('修改订单') }}
          </button>
        </span>
      </div>

      <!-- 订单列表 -->
      <div class="order">
        <!-- 表头 -->
        <ul class="submit_box_header">
          <li class="inputBox" :style="['obligation', 'OrderDetails', 'purchased', 'shipping'].indexOf(
      orderStatus
    ) != -1
      ? 'flex: 0 0 186px'
      : ''
      " @click="
      checked = !checked
    allChoice()
      ">
            <input class="headCheckBox" style="margin-right: 10px" type="checkbox" name="" id="" v-model="checked"
              @change="allChoice" />
            {{ $fanyi('全选') }}
          </li>
          <li class="goods">{{ $fanyi('产品') }}</li>
          <li class="detail">{{ $fanyi('属性') }}</li>
          <li class="num">
            <span :style="['obligation', 'OrderDetails', 'purchased', 'shipping'].indexOf(
      orderStatus
    ) != -1
      ? 'margin-left: 20px'
      : ''
      ">{{ $fanyi('单价') }}</span>
          </li>
          <!-- 付款后调查在库数量 -->
          <li class="price" v-if="['purchased', 'purchase'].indexOf(orderStatus) != -1">
            {{ $fanyi('调查在库数量') }}
          </li>
          <li class="num" v-if="[
      'obligation',
      'offer',
      'delete',
      'editing',
      'temporary',
      'cancel',
    ].indexOf(orderStatus) != -1
      ">
            {{ $fanyi('购买数量') }}
          </li>

          <li class="num" v-else>
            {{ $fanyi('实际购买数量') }}
          </li>
          <!-- 付款前调查在库数量 -->
          <li class="price" v-if="['obligation', 'OrderDetails', 'shipping'].indexOf(orderStatus) !=
      -1
      ">
            {{ $fanyi('调查在库数量') }}
          </li>
          <li class="total">{{ $fanyi('总价') }}</li>
          <!-- v-if="['purchased', 'purchase'].indexOf(orderStatus) != -1" -->

          <li class="options" v-if="['offer', 'obligation', 'temporary', 'editing'].indexOf(
      orderStatus
    ) != -1
      ">
            {{ $fanyi('附加服务费用') }}
            <img style="margin-left: 2px;" @click="goservices" src="../../../../../assets/question.svg" alt="" />
          </li>
          <li class="remark">{{ $fanyi('备注') }}</li>
          <li v-if="['obligation', 'purchased', 'shipping', 'purchase'].indexOf(
      orderStatus
    ) != -1
      " class="constDetail">
            {{ $fanyi('费用明细') }}
          </li>
        </ul>
        <div class="goodsBox" :style="!showAllGoods ? 'height:200px' : ''">
          <!-- 数据列表 -->
          <div v-for="(aitem, aindex) in newList" :key="aitem.shop_id">
            <h1>
              <div class="checkedBox" @click="
      aitem.checked = !aitem.checked
    shopAll(aindex)
      ">
                <input type="checkbox" name="" id="" v-model="aitem.checked" @change="shopAll(aindex)" />
              </div>
              {{ $fanyi('店铺') }}：{{ aitem.shop_name }}
              <span class="img1688box"><img src="../../../../../assets//al.svg" alt="" /></span>
            </h1>

            <!-- {{ aitem.list }} 商品列表 -->
            <div class="goodsCon">
              <ul v-for="item in aitem.list" :key="item.id">
                <!-- 全选 -->
                <li class="inputBox" @click="
      item.checked = !item.checked
    radio(aindex)
      ">
                  <input type="checkbox" name="" id="" @change="radio(aindex)" v-model="item.checked" />
                  <!-- 商品番号 -->
                  <span class="rowNumber">
                    {{ item.sorting }}
                  </span>
                  <el-popover placement="right" trigger="hover">
                    <img :src="item.pic" alt="" style="width: 300px; height: 300px" />
                    <img @click="
      $fun.toCommodityDetails({
        goods_id: item.goods_id,
        shop_type: item.shop_type,
      })
      " :src="item.pic" alt="" slot="reference" />
                  </el-popover>
                </li>
                <!-- 产品 -->
                <li class="goods">
                  <el-popover placement="bottom" width="400" trigger="hover">
                    {{ item.goods_title }}
                    <div class="showGoodsTitle" :title="item.goods_title" slot="reference" @click="
      $fun.toCommodityDetails({
        goods_id: item.goods_id,
        shop_type: item.from_platform,
      })
      ">
                      <font>{{ item.goods_title }}</font>
                    </div>
                  </el-popover>
                </li>
                <!-- 详细 -->
                <li class="detail" :class="item.detailWindow == true
      ? 'active goodsDetail'
      : 'goodsDetail'
      ">
                  <!-- 展示 -->
                  <el-popover placement="bottom" trigger="hover">
                    <div class="goodsDetailAll">
                      <div class="goodsDetailAllOneBox" v-for="(detailItem, detailIndex) in item.detail"
                        :key="detailIndex" :title="detailItem.key + ':' + detailItem.value">
                        <h1 class="detailTitle">{{ detailItem.key }}</h1>
                        <p>
                          <span class="detailBody">{{ detailItem.value }}</span>
                        </p>
                      </div>
                    </div>

                    <div slot="reference" class="showDetail" v-if="['temporary', 'editing'].indexOf(orderStatus) == -1">
                      <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                        :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay" @click.stop="
                                                                                                                                                                                                                                                                                                                                                                                                                          ;['temporary', 'editing'].indexOf(orderStatus) != -1
      ? openDetailWindow(item)
      : ''
      ">
                        {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                      </p>
                      <img @click.stop="
                                                                                                                                                                                                                                                                                                                                                                                          ;['temporary', 'editing'].indexOf(orderStatus) != -1
      ? openDetailWindow(item)
      : ''
      " v-if="['temporary', 'editing'].indexOf(orderStatus) != -1
      " src="../../../../../assets/preview_order/baianji.svg" alt="" />
                    </div>
                  </el-popover>

                  <el-popover placement="bottom" trigger="click">
                    <div class="detailWindowBody">
                      <div class="detailOpt" v-for="(goodsGuiGeItem,
      goodsGuiGeIndex) in item.pageSpecification" :key="goodsGuiGeIndex">
                        <h1>{{ goodsGuiGeItem.key }}</h1>
                        <div class="optClass">
                          <span v-for="(actGuige, actIndex) in goodsGuiGeItem.value" :key="actIndex"
                            :title="actGuige.name" @click="
      goodsGuiGeItem.active = actIndex
    goodsDetailChoice(
      item.detail[goodsGuiGeIndex],
      actGuige,
      item
    )
    $forceUpdate()
      " :class="actIndex == goodsGuiGeItem.active ? 'active' : ''
      ">{{ actGuige.name }}</span>
                        </div>
                      </div>

                    </div>
                    <!-- 可选择 -->
                    <div slot="reference" class="showDetail" v-if="['temporary', 'editing'].indexOf(orderStatus) != -1">
                      <div class="showDetailCon">
                        <p v-for="(bitem, bindex) in item.detail" :key="bitem.key + bindex"
                          :title="bitem.key + ':' + bitem.value" class="goodsDetailOpt ispay">
                          {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                        </p>
                      </div>

                      <img @click="
                                                                                                                                                                                                                                                                                                                                                                                          ;['temporary', 'editing'].indexOf(orderStatus) != -1
      ? openDetailWindow(item)
      : ''
      " v-if="['temporary', 'editing'].indexOf(orderStatus) != -1
      " src="../../../../../assets/preview_order/baianji.svg" alt="" />
                    </div>
                  </el-popover>
                </li>
                <!-- 属性 -->

                <!-- 单价 -->
                <li class="price">
                  <span>${{
      $fun.RMBNumSegmentation(
        $fun.ceil(
          (item.confirm_price && item.confirm_price > 0
            ? item.confirm_price
            : item.price) * datas.exchange_rate
        )
      )
    }}</span>
                  <span>({{
        $fun.RMBNumSegmentation(
          item.confirm_price && item.confirm_price > 0
            ? item.confirm_price
            : item.price
        )
      }}￥)</span>
                </li>
                <!-- 付款后调查在库数量 -->
                <li class="num" v-if="['purchased', 'purchase'].indexOf(orderStatus) != -1">
                  {{ item.confirm_num }}
                </li>
                <!-- 购买数量 -->
                <li class="num">
                  <el-input-number v-if="$route.query.type == 'obligation' &&
      ['editing'].indexOf(orderStatus) != -1
      " v-model="item.num" step-strictly @change="getTotalPrice(item)" :min="1" size="mini"></el-input-number>

                  <el-input-number v-else-if="['temporary', 'editing'].indexOf(orderStatus) != -1
      " v-model="item.num" step-strictly @change="getTotalPrice(item)" :min="1" size="mini"></el-input-number>
                  <span v-else-if="[
      'obligation',
      'offer',
      'delete',
      'editing',
      'temporary',
    ].indexOf(orderStatus) == -1
      ">
                    {{ item.purchase_num }}
                  </span>
                  <span v-else>{{ item.num }}</span>
                </li>
                <!-- 付款前调查在库数量 -->
                <li class="num" v-if="['obligation', 'OrderDetails'].indexOf(orderStatus) != -1
      ">
                  {{ item.confirm_num }}
                </li>
                <!-- 总价 -->
                <li class="total">
                  <span>${{
      $fun.RMBNumSegmentation(
        $fun.ceil(item.totalPrice * datas.exchange_rate)
      )
    }}
                    ({{ $fun.RMBNumSegmentation(item.totalPrice) }}￥)</span>
                </li>

                <!-- 附加服务 -->
                <li class="options" v-if="['offer', 'obligation', 'temporary', 'editing'].indexOf(
      orderStatus
    ) != -1
      ">
                  <!-- 鼠标经过显示option列表 -->
                  <el-popover placement="bottom" trigger="hover" popper-class="dditionalServicesPro addtionalPopover">
                    <div class="additionalServices">
                      <p class="ohead">
                        <span class="optionsCheck">
                          {{ $fanyi('附加服务选择') }}
                        </span>
                        <span class="optionsNum">{{ $fanyi('数量') }}</span>
                        <span class="optionsFba"></span>
                        <span class="optionsPrice">{{ $fanyi('单价') }}</span>
                        <span class="optionsPrice">
                          {{ $fanyi('预计费用') }}
                        </span>
                      </p>
                      <p class="obody" v-for="(optionListitem, index) in item.option" :key="'optionsList' + index"
                        v-show="optionListitem.checked">
                        <span>
                          {{ optionListitem.tag }}
                          {{ optionListitem.name_translate }}</span>
                        <span class="num">{{ optionListitem.num }}</span>
                        <span class="fba">
                          <el-popover placement="bottom" width="200" trigger="click">
                            {{ optionListitem.fba }}
                            <span slot="reference" v-show="optionListitem.fba" class="showfba">
                              {{ optionListitem.fba }}
                            </span>
                          </el-popover>
                        </span>
                        <span class="price">
                          <span>
                            ${{ $fun.EURNumSegmentation($fun.ceil(optionListitem.price * datas.exchange_rate)) }}
                          </span>
                          <span>
                            ({{ $fun.RMBNumSegmentation(optionListitem.price) }}￥)
                          </span>
                        </span>
                        <span class="price">
                          <span>
                            ${{ $fun.EURNumSegmentation($fun.ceil(optionListitem.price * optionListitem.num
      * datas.exchange_rate)) }}
                          </span>
                          <span>
                            ({{ $fun.RMBNumSegmentation(optionListitem.price * optionListitem.num) }}￥)
                          </span>
                        </span>
                      </p>
                      <div class="foot">
                        <p>
                          {{ $fanyi('合计约为') }}：<font>${{ oneGoodsOptionTotalPrice }}</font>
                        </p>
                      </div>
                    </div>

                    <div slot="reference" class="obligationOptionInput" v-if="[
      'obligation',
      'purchased',
      'offer',
      'purchase',
      'delete',
    ].indexOf(orderStatus) != -1 && item.option != '[]'
      " @mousemove="setOrderOption(item)">
                      <p class="optList" v-for="(optionItem, index) in item.option" :key="index"
                        v-show="optionItem.checked">
                        {{ optionItem.tag }}&nbsp;{{
      optionItem.name_translate
    }}*{{ optionItem.num }}
                      </p>
                      <p class="opi" v-if="item.option.length == 0">
                        {{ $fanyi('未选择服务') }}
                      </p>
                    </div>
                  </el-popover>
                  <!-- 数标点击显示option列表 -->
                  <el-popover ref="popoveraite" placement="bottom" trigger="hover"
                    popper-class="dditionalServicesPro addtionalPopover">
                    <div class="additionalServices">
                      <p class="ohead">
                        <span>{{ $fanyi('附加服务选择') }}</span>
                        <span>{{ $fanyi('数量') }}</span>
                        <span></span>
                        <span>{{ $fanyi('单价') }}</span>
                        <span>{{ $fanyi('预计费用') }}</span>
                      </p>
                      <p class="obody" v-for="(optionListitem, index) in item.option_old"
                        :key="'optionListitem' + index">
                        <span><input type="checkbox" class="checkOptionBox" :checked="optionListitem.checked" @click="
      optionListitem.checked = !optionListitem.checked
    setOptionsNum(optionListitem, item.num)
    setOneGoodsOptionTotalPrice(item, item.num)
      " />
                          {{ optionListitem.tag }})
                          {{ optionListitem.name_translate }}</span>
                        <span class="num"><input type="text" v-model="optionListitem.num" @change="
      setOneGoodsOptionTotalPrice(item, item.num)
      " oninput="this.value=Number(this.value.replace(/[^\d]/g,''))" /></span>
                        <span class="fba">
                          <textarea v-if="optionListitem.checked &&
      optionListitem.name == 'SKU'
      " v-model="optionListitem.fba" placeholder="FBA（FNSKU）" />
                        </span>
                        <span class="price"><span>${{
      $fun.EURNumSegmentation(
        $fun.ceil(
          optionListitem.price * datas.exchange_rate
        )
      )
    }}</span>
                          <span>({{
        $fun.RMBNumSegmentation(optionListitem.price)
      }}￥)</span></span>
                        <span class="price"><span>${{
        $fun.EURNumSegmentation(
          $fun.ceil(
            optionListitem.price *
            optionListitem.num *
            datas.exchange_rate
          )
        )
      }}</span>
                          <span>({{
        $fun.RMBNumSegmentation(
          optionListitem.price * optionListitem.num
        )
      }}￥)</span></span>
                      </p>
                      <div class="foot">
                        <p>
                          {{ $fanyi('合计约为') }}：<font>${{
      $fun.RMBNumSegmentation(
        $fun.ceil(
          oneGoodsOptionTotalPrice * datas.exchange_rate
        )
      )
    }}
                            ({{
        $fun.RMBNumSegmentation(oneGoodsOptionTotalPrice)
      }}￥)</font>
                        </p>
                        <p>
                          <button class="react" @click="optionReact(item)">
                            {{ $fanyi('重置') }}</button><button class="save"
                            @click="saveOrderOption(item, item.sorting)">
                            {{ $fanyi('保存') }}
                          </button>
                        </p>
                      </div>
                    </div>
                    <div class="optionInput" slot="reference" v-show="['temporary', 'editing'].indexOf(orderStatus) != -1
      ">
                      <div class="inputOption haveData" @click="setOrderOption(item)">
                        <p v-if="item.option.every((i) => i.num == 0)" style="
                            color: #999;
                            text-align: left;
                            padding-left: 15px;
                            padding-top: 12px;
                            font-weight: normal;
                            padding-right: 10px;
                          ">
                          Click to select another service
                        </p>
                        <p v-else v-show="!!(typeof item.option != 'string') &&
      eeitem.checked == true &&
      eeitem.num > 0
      " class="list" v-for="(eeitem, index) in item.option" :key="index">
                          {{ eeitem.tag }}&nbsp;{{ eeitem.name_translate }}*{{
      eeitem.num
    }}
                        </p>
                      </div>
                    </div>
                  </el-popover>
                  <div class="inputOption haveData" @click="setOrderOption(item)" v-show="datas.status > 30">
                    <p v-show="!!(typeof item.option != 'string') &&
      eeitem.checked == true &&
      eeitem.num > 0
      " class="list" v-for="(eeitem, index) in item.option" :key="index">
                      {{ eeitem.tag }}&nbsp;{{ eeitem.name_translate }}*{{
      eeitem.num
    }}
                    </p>
                  </div>
                </li>
                <!-- 备注 -->
                <li class="remark">
                  <span v-if="['temporary', 'editing', 'offer'].indexOf(orderStatus) == -1">
                    {{ item.client_remark }}
                  </span>
                  <textarea v-if="['temporary', 'editing'].indexOf(orderStatus) != -1" v-model="item.client_remark"
                    :placeholder="$fanyi('您可以在这边添加商品备注')"></textarea>
                  <p class="beizhu" v-if="['offer'].indexOf(orderStatus) != -1">
                    <span v-if="item.client_remark">
                      {{ item.client_remark }}</span>
                    <span v-else style="color: #999999">{{
      $fanyi('没有备注')
    }}</span>
                  </p>
                </li>
                <!-- 费用明细 -->
                <!-- v-if="
                      ['obligation', 'purchased','shipping', 'purchase'].indexOf(
                        orderStatus
                      ) != -1
                    " -->
                <li class="constDetail" v-if="['obligation', 'purchased', 'shipping', 'purchase'].indexOf(
        orderStatus
      ) != -1
      ">
                  <button @click="openCostBreakdown(item.id, item)">
                    {{ $fanyi('费用明细') }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="showAllGoods" v-if="true">
          <span></span>
          <div style="display: flex; justify-content: center; align-items: center">
            <span v-if="!showAllGoods" style="margin-right: 10px;">Open</span>
            <span v-else style="margin-right: 10px;">Put away</span>
            <img class="jiahaoIcon" v-if="!showAllGoods" src="../../../../../assets/icon/putdown.png"
              @click="showAllGoods = !showAllGoods" />
            <img class="jiahaoIcon" v-else src="../../../../../assets/icon/putdown1.png"
              @click="showAllGoods = !showAllGoods" />
          </div>
        </div>
      </div>
    </div>

    <!-- 提出订单操作栏 -->
    <div class="operatingOptions" v-if="['warehouse'].indexOf(orderStatus) == -1">
      <div class="operatingOptionsLeft">
        <div class="choies">
          <input class="chioseInput" type="checkbox" name="" id="" v-model="checked" @change="allChoice" />
          <button class="qx" @click="
      checked = !checked
    allChoice()
      ">
            {{ $fanyi('全选') }}
          </button>
        </div>
        <div class="caozuoBox">
          <!-- 加入商品库 -->
          <div class="caoZuoAnNiuGroup" v-if="['temporary', 'editing'].indexOf(orderStatus) == -1">
            <button class="addLibrary" @click="addLibraryOfGoods">
              {{ $fanyi('加入商品库') }}
            </button>
          </div>

          <div class="caoZuoAnNiuGroup" v-else>
            <el-popover placement="top" min-width="180" trigger="click">
              <el-button slot="reference" class="opt">{{
      $fanyi('操作')
    }}</el-button>

              <!-- 删除 -->
              <div class="delBtn" v-if="['temporary', 'editing'].indexOf(orderStatus) != -1" @click="delOrder">
                {{ $fanyi('删除') }}
              </div>
              <!--  -->
              <div class="addgoods" v-if="['temporary', 'editing'].indexOf(orderStatus) != -1" @click="addGoodsbtn()">
                {{ $fanyi('追加商品') }}
              </div>

              <div class="addLibraryOfGoods" @click="addLibraryOfGoods">
                {{ $fanyi('加入商品库') }}
              </div>
            </el-popover>
          </div>
        </div>
        <!-- 底部订单付款 -->
        <div class="orderDataBox1" v-if="orderStatus == 'obligation'">
          <span> {{ $fanyi('数量总计') }}：{{ quantityOfCommodity }} </span>
          <div v-if="[
      'obligation',
      'offer',
      'purchase',
      'purchased',
      'delete',
      'shipping',
    ].indexOf(orderStatus) != -1
      ">
            {{ $fanyi('总体积') }}：&nbsp;{{
      $fun.ceil(datas.orderVolume_m3)
    }}&nbsp;m³
          </div>
        </div>
        <div class="orderDataBox" v-else>
          <span> {{ $fanyi('数量总计') }}：{{ quantityOfCommodity }} </span>
          <span v-if="[
      'obligation',
      'offer',
      'purchase',
      'purchased',
      'delete',
      'shipping',
    ].indexOf(orderStatus) != -1
      ">{{ $fanyi('总体积') }}：&nbsp;{{
      $fun.ceil(datas.orderVolume_m3)
    }}&nbsp;m³</span>
        </div>
      </div>

      <!--右边 -->
      <div class="operatingOptionsRight">
        <!-- 支付 -->
        <div class="jine">
          <!-- 优惠券 -->
          <div class="youhuijuan" v-if="orderStatus == 'obligation'">
            <!-- -->
            <el-checkbox v-model="couponChecked" @change="showCouponList">
              {{ $fanyi('使用优惠券') }}
            </el-checkbox>
            <span>{{ datas.coupon_num }}</span>
          </div>
          <div class="left">
            <div class="zongjiaBox">
              <span> {{ $fanyi('实际支付金额') }}:</span>
              <span>
                <font class="priceBox">￥{{ orderPriceGroup.sum_discount_price }}</font>
              </span>
            </div>
            <div class="zongjiaBoxMeiYuan">
              <span class="huiLv"> （1￥≈${{ datas.exchange_rate }}） </span>
              <span class="meiyuanPrice">
                ${{ $fun.EURNumSegmentation($fun.ceil(orderPriceGroup.sum_discount_price_dollar)) }}
              </span>
            </div>
          </div>
          <!-- 付款 -->
          <div class="caoZuoBtnBox" v-if="orderStatus == 'obligation'">
            <button class="payBtn" @click="payMent">
              {{ $fanyi('付款') }}
            </button>
          </div>
          <!-- 提出配送单 -->
          <div class="caoZuoBtnBox" v-if="['temporary'].indexOf(orderStatus) != -1">
            <button class="baocun" @click="OrdersSubmitted(10)">
              {{ $fanyi('临时保存') }}
            </button>
            <button class="tichu" @click="OrdersSubmitted(20)">
              {{ $fanyi('提出订单') }}
            </button>
          </div>
          <!-- 提出订单 -->

          <div class="caoZuoBtnBox" v-if="['editing'].indexOf(orderStatus) != -1">
            <button @click="pageLoad">{{ $fanyi('取消') }}</button>
            <button class="chongXinTiChu" @click="resubmit">
              {{ $fanyi('重新提出') }}
            </button>
          </div>
        </div>

        <!-- 2222222 -->
      </div>
    </div>
    <SpecialNoteVue v-if="['temporary', 'editing', 'offer', 'delete'].indexOf(orderStatus) != -1
      " />
    <!--    RAKUMART订单价值-->
    <RAKUMARTOrderValue v-if="[
      'obligation',
      'purchased',
      'shipping',
      'purchase',
      'warehouse',
    ].indexOf(orderStatus) != -1
      " />
    <ModeOofTransportation @Selectivelogistics="Selectivelogistics" ref="odeOofTrans" v-if="[
      'purchased',
      'shipping',
      'purchase',
      'warehouse',
      'obligation',
    ].indexOf(orderStatus) != -1
      " />
    <shippingInformation v-if="ShippingOrderInformation.length !== 0" :list="ShippingOrderInformation" />
    <!-- 购物车弹窗 -->
    <el-dialog title="" class="cartDia" :visible.sync="showCart" width="1400px">
      <addGoods v-if="showCart"></addGoods>
    </el-dialog>
    <CostBreakdown v-if="showCostBreakdown" />
    <el-dialog :visible.sync="couponDetailDialogVisible" width="480px" :show-close="false">
      <Coupons @cloneDialogVisible="cloneDialogVisible" :status="datas.status" @getActiveCoupon="getActiveCoupon"
        :key="timer" />
    </el-dialog>

    <importdetails ref="showverdetalles"></importdetails>

    <!-- 库存为0 提示 为删除或者修改订单 -->
    <el-dialog :visible.sync="zeroInventoryGoodsDialogVisible" width="400px" :show-close="false">
      <div class="zeroInventoryGoodsDialogVisibleHeader flexAndCenter">
        <div>{{ $fanyi('提醒') }}</div>
        <img src="../../../../../assets/cancel.png" alt="" @click="zeroInventoryGoodsDialogVisible = false" />
      </div>
      <div class="zeroInventoryGoodsDialogVisibleCenter">
        <img src="../../../../../assets/remind.png" alt="" />
        <div>
          {{
          $fanyi(
          '当前订单商品暂无库存，无法完成购买。您可以调整订单重新提出 也可以直接删除该订单。'
          )
          }}
        </div>
      </div>
      <div class="zeroInventoryGoodsDialogVisibleFooter flexAndCenter">
        <div @click="delObligationOrder">{{ $fanyi('删除订单') }}</div>
        <div @click="
      oldobligation = orderStatus
    orderStatus = 'editing'
    dataProcessing()
    zeroInventoryGoodsDialogVisible = false
      ">
          {{ $fanyi('修改订单') }}
        </div>
      </div>
    </el-dialog>
    <el-dialog :lock-scroll="false" :visible.sync="goodsPriceUpdateDetailDialogVisible" width="1000px"
      :show-close="false">
      <goodsPriceUpdateDetail ref="Changedetails" @cloneGoodsPriceUpdateDetailDialogVisible="cloneGoodsPriceUpdateDetailDialogVisible
      " />
    </el-dialog>
    <customerPrompt ref="customerPrompt" style="background: transparent; box-shadow: none; height: 0px">
    </customerPrompt>
  </div>

  <placingSuccessfullyPage v-else ref="querenyemian" :order_sn="datas.order_sn"></placingSuccessfullyPage>
</template>
<script>
import addGoods from './components/addGoods.vue'
import { getCurrentDate } from '../../../../../utlis/date'
import HomePageTop from '../../../../../components/head/HomePageTop.vue'
import RAKUMARTOrderValue from './newCom/RAKUMARTOrderValue.vue'
import { mapState } from 'vuex'

import SpecialNoteVue from './newCom/SpecialNote.vue'
import ModeOofTransportation from './newCom/ModeOofTransportation.vue'
import CostBreakdown from './components/CostBreakdown.vue'
import Coupons from './components/Coupons.vue'
import customerPrompt from './components/customerPrompt'
import placingSuccessfullyPage from './newCom/placingSuccessfullyPage'
import SustituciónDeBienes from './newCom/SustituciónDeBienes.vue'
import whyDiaVue from './newCom/whyDia.vue'
import comBlockVue from './newCom/comBlock.vue'
import importdetails from './components/importdetails.vue'
import shippingInformation from './components/shippingInformation.vue'
import goodsPriceUpdateDetail from './components/goodsPriceUpdateDetail.vue'
export default {
  name: 'order',
  data() {
    return {
      dialogVisible: false,
      fullscreenLoading: true,
      oldobligation: '',
      logistics: '',
      status_name: '',
      timer: '',
      order_sn: '',

      ind: 0,
      couponDetailDialogVisible: false,
      placingSuccessfully: false, //是否显示成功提出订单页面
      orderStatus: '', //订单状态名(临时保存的：temporary，报价中：offer，等待付款：obligation，购买中：purchase，购买完成：purchased，删除：delete,修改中 : editing, warehouse 上架完了)
      goodsAct: {}, //单个操作的商品数据
      userActiveAdd: {
        importer: {},
        consignee: {},
      },

      userActiveAddName: {
        importer: '',
        consignee: '',
      },

      sorting: 1, //番号
      couponChecked: false,
      activeIds: [], //选中的id
      ids: [], //获取列表时的所有id
      newList: [], // 列表数据
      couponList: [], //优惠券列表
      checked: false, //   全选状态
      showCart: false, // 购物车弹窗状态
      optionList: [], // 附加服务列表
      zeroInventoryGoods: 0,
      oneGoodsOptionTotalPrice: 0, //一条商品的附加服务总价
      showOptionList: false, //附加服务列表弹窗状态
      checkItem: {}, //正在操作的列表
      datas: {},
      ShippingOrderInformation: [],
      orderPriceGroup: {}, //价格数据
      showPriceDetail: false, //附加服务明细
      //运输方式下拉框控制
      transportationControl: {
        show: false,
        active: 0,
        options: [
          { name: this.$fanyi('海上运输'), value: 0 },
          // { name: "航空运输", value: 1 },
          // { name: "ocs", value: 2 },
        ],
      },
      goodsPriceUpdateDetailDialogVisible: false,
      zeroInventoryGoodsDialogVisible: false,
      showCostBreakdown: false, //商品金额弹窗开关
      CostBreakdownId: '', //商品金额弹窗id
      youHuiQuanShiYong: false, //使用优惠券或代金券判断
      daijinSmall: false, //使用优惠券判断
      youhuiSmall: false, //使用优惠券判断
      CouponsShow: false, //使用优惠券弹窗
      CouponsData: {
        ids: '',
        str: '',
      }, // 优惠券使用数据

      isedit: false,
      Switchlogistics: '',
      quantityOfCommodity: 0, //商品数量
      client_remark: '', //订单特殊要求备注
      haveYouHuiQuan: false, //是否拥有优惠券
      wuLiuOptions: [],
      showAllGoods: true, //商品表格是否显示全部
    }
  },

  components: {
    addGoods,
    HomePageTop,
    RAKUMARTOrderValue,

    SpecialNoteVue,
    ModeOofTransportation,
    CostBreakdown,
    Coupons,
    customerPrompt,
    placingSuccessfullyPage,
    SustituciónDeBienes,
    comBlockVue,
    importdetails,
    whyDiaVue,
    shippingInformation,
    goodsPriceUpdateDetail,
  },

  computed: {
    ...mapState(['rate', 'excelOrder']),
    getCurrentDate() {
      return getCurrentDate(2)
    },
    shouqi() {
      let scrollTop = this.$store.state.topScrollMoreHeight
      if (scrollTop >= 190) {
        return true
      } else if (scrollTop <= 100) {
        return false
      }
      return true
    },
    orderOptons() {
      let allOptionPrice = 0
      let activeIds = []

      for (let a in this.newList) {
        for (let b in this.newList[a].list) {
          for (let c in this.newList[a].list[b].option) {
            allOptionPrice +=
              this.newList[a].list[b].option[c].price *
              this.newList[a].list[b].option[c].num
          }
        }
      }
      this.activeIds = activeIds
      return allOptionPrice
    },

    // 计算店铺数量、产品数量和附加服务明细
    numberOfStores() {
      let stores = 0
      let goods = 0
      let useOption = []
      for (let i in this.newList) {
        stores++
        for (let j in this.newList[i].list) {
          for (let e in this.newList[i].list[j].option) {
            useOption.push({
              name: this.newList[i].list[j].option[e].name,
              num: this.newList[i].list[j].option[e].num,
              // total: (
              //   this.newList[i].list[j].option[e].price *
              //   this.newList[i].list[j].option[e].num
              // ).toFixed(2),
            })
          }
          goods++
        }
      }
      let optionList = JSON.parse(JSON.stringify(this.optionList))
      optionList.forEach((item) => {
        item.num = 0
        useOption.forEach((btem) => {
          if (item.name == btem.name) {
            item.num = Number(item.num) + Number(btem.num)
          }
        })
        item.total = this.$fun.ceil(item.num * item.price)
      })
      // 不显示为0的附加服务
      for (let i = 0; i < optionList.length; i++) {
        if (optionList[i].num == 0) {
          optionList.splice(i, 1)
          i--
        }
      }
      return {
        stores: stores,
        goods: goods,
        useOption: optionList,
      }
    },
  },

  created() {
    this.pageLoad()
    this.getwuLiu()

    if (this.orderStatus == 'purchase' || this.orderStatus == 'purchased') {
      this.$api
        .orderPrompt({
          order_sn: this.$route.query.order_sn,
        })
        .then((res) => {
          if (!res.data) {
            this.$confirm(
              this.$fanyi(
                '因该订单实际采购数为0,无法进行下一步流程，请问是否取消该订单?'
              ),
              '提示',
              {
                confirmButtonText: this.$fanyi('是'),
                cancelButtonText: this.$fanyi('否'),
                type: 'warning',
              }
            ).then(() => {
              this.$api
                .orderDelete({
                  order_sn: this.$route.query.order_sn,
                })
                .then((res) => {
                  if (!res.success) return this.$message(res.msg)
                  this.$message({
                    type: 'success',
                    message: this.$fanyi('删除成功'),
                  })
                  this.$router.back()
                })
            })
          }
        })
    }
  },
  mounted() {
    // document.addEventListener("click", this.bodyCloseMenus);
  },

  methods: {
    //点击li的全选
    selectAllLi() {
      this.checked = !this.checked
      this.allChoice()
    },
    //点击商品图片去往商品详情
    clickShopImg(item) {
      this.$fun.toCommodityDetails({
        goods_id: item.goods_id,
        shop_type: item.shop_type,
      })
    },
    //点击商品名称
    clickGoodsTitle(item) {
      this.$fun.toCommodityDetails({
        goods_id: item.goods_id,
        shop_type: item.shop_type,
      })
    },
    //点击商品属性
    clickGoodsSpec(item) {
      let strArr = ['temporary', 'editing']
      if (strArr.indexOf(orderStatus) != -1) {
        this.openDetailWindow(item)
      }
    },
    //点击商品规格
    clickGoodsGuiGe(item, specItem, specIndex, goodsGuiGeIndex) {
      this.goodsGuiGeItem.active = specIndex
      this.goodsDetailChoice(
        item.detail[goodsGuiGeIndex],
        specItem,
        item)
      this.$forceUpdate()
    },
    //点击产品详情
    clickGoodsDetails(item) {
      this.$fun.toCommodityDetails({
        goods_id: item.goods_id,
        shop_type: item.shop_type,
      })
    },
    // 获取变更明细
    async getdetails(id) {
      const { data } = await this.$api.orderChangeDetails({
        order_detail_id: id,
      })

      this.$refs.Changedetails.Changedetail = data
    },
    goservices() {
      window.open('/additional-services')
    },
    showverdetalles(item) {
      this.$refs.showverdetalles.showverdetalles = true
      this.$refs.showverdetalles.orderOneGoodsFee(item, this.Switchlogistics)
    },
    // 展示全部
    all(item) {
      if (item.type == false) {
        item.len = item.option.length
      }
      if (item.type == true) {
        item.len = 1
      }
      this.$forceUpdate()
    },
    // 获取物流方式列表
    getwuLiu() {
      this.$api.internationalLogisticsTransportation().then((res) => {
        if (res.code != 0) return
        if (res.data.length == 0) return false
        this.wuLiuOptions = res.data
        this.logistics = res.data[0].name
      })
    },
    // 修改订单操作
    changeOrder() { },
    // 商品详情选择，传入参数：已选中的选项，选择的选项,单条商品数据
    goodsDetailChoice(detail, checkedDetail, oneGoods) {
      detail.value = checkedDetail.name
      let str = []
      oneGoods.detail.forEach((item) => {
        str.push(item.value)
      })
      str = str.join('㊖㊎')
      this.getPrice(str, oneGoods)
    },
    // 根据选择条件匹配价格
    getPrice(str, oneGoods) {
      oneGoods.totalPrice = Number(
        this.$fun.ceil(oneGoods.num * oneGoods.price)
      )
      return
      str = '"' + str + '"'

      let data = {}
      // 循环遍历对象寻找
      if (typeof oneGoods.goods_inventory != 'object') {
        var goods_inventory = JSON.parse(oneGoods.goods_inventory)
      } else {
        var goods_inventory = oneGoods.goods_inventory
      }
      Object.keys(goods_inventory).forEach((key) => {
        if (key == str) {
          data = goods_inventory[key]
          return false
        }
      })
      oneGoods.price = data.price
      // 计算总价
      oneGoods.totalPrice = Number(
        this.$fun.ceil(oneGoods.num * oneGoods.price)
      )
      this.calculateOrder()
      this.$forceUpdate()
    },
    // 根据传入的匹配物流
    Selectivelogistics(id, index) {
      this.Switchlogistics = id
      this.ind = index
    },
    // 加入商品库
    addLibraryOfGoods() {
      // 循环查找已选中的商品
      let chechkedGoods = []
      this.newList.forEach((newListItem) => {
        newListItem.list.forEach((newListItemListItem) => {
          newListItemListItem = JSON.parse(JSON.stringify(newListItemListItem))
          if (newListItemListItem.checked)
            chechkedGoods.push({
              shop_type: 1688,
              goods_id: newListItemListItem.goods_id,
              goods_title: newListItemListItem.goods_title,
              shop_id: newListItem.shop_id,
              shop_name: newListItem.shop_name,
              img_url: newListItemListItem.pic,
              price_range: newListItemListItem.price_range, //价格幅度
              detailChinese: ((
                detailEnglish,
                pageSpecification,
                pageSpecificationChinese
              ) => {
                let detailChinese = []
                detailEnglish.forEach((item, index) => {
                  detailChinese.push({
                    key: pageSpecificationChinese[index].key,
                    value:
                      pageSpecificationChinese[index].value[
                        pageSpecification[index].active
                      ].name,
                  })
                })
                return detailChinese
              })(
                newListItemListItem.detail,
                newListItemListItem.pageSpecification,
                newListItemListItem.pageSpecificationChinese
              ),
              //规格核对
              specification_checked: ((
                pageSpecification,
                pageSpecificationChinese
              ) => {
                let specification_checked = []
                pageSpecification.forEach(
                  (pageSpecificationItem, pageSpecificationIndex) => {
                    specification_checked.push({
                      key: pageSpecificationChinese[pageSpecificationIndex].key,
                      value:
                        pageSpecificationChinese[pageSpecificationIndex].value[
                          pageSpecificationItem.active
                        ].name,
                    })
                  }
                )
                return specification_checked
              })(
                newListItemListItem.pageSpecification,
                newListItemListItem.pageSpecificationChinese
              ),

              option: JSON.stringify(newListItemListItem.option),
            })
        })
      })

      // return;
      if (chechkedGoods.length == 0)
        return this.$message(this.$fanyi('请至少选择一个商品'))

      this.$api
        .favoriteInventoryBatchSave({
          data: JSON.stringify(chechkedGoods),
        })
        .then((res) => {
          if (res.code != 0) return
          this.$message.success(this.$fanyi('加入商品库成功'))
        })
    },
    // 计算单条商品订单的附加服务费用(只计算选中的附加服务)
    setOneGoodsOptionTotalPrice(item, num) {
      // 进行判断，如果除了摄影和配套，其他的附加服务的数量大于购买数量，那就让他等于购买数量

      let sum = 0

      item.option_old.forEach((tt) => {
        if (tt.name != '摄影' && tt.name != '配套') {
          if (tt.num > num) {
            tt.num = num
          }
        }

        if (tt.checked == true) {
          sum += tt.num * tt.price
        }
      })

      this.oneGoodsOptionTotalPrice = sum
      this.$forceUpdate()
    },
    // 追加商品
    addGoodsbtn() {
      if (this.numberOfStores.goods >= 60) {
        return this.$message.warning(
          this.$fanyi('单个订单不能超过60番产品，请调整提出番数')
        )
      }
      this.showCart = !this.showCart
    },

    // 付款按钮
    // 跳转到付款页面
    payMent() {
      if (this.$refs.odeOofTrans.userActOpt == -1) {
        return this.$message.warning(this.$fanyi('请至少选择一项物流方式'))
      }
      // 修改物流方式成功之后跳转，否则弹出错误信息
      this.$api
        .updateOrderLogistics({
          order_sn: this.$route.query.order_sn,
          logistics: this.datas.orderOfferLogistics[
            this.$refs.odeOofTrans.userActOpt
          ].logistics.id,
        })
        .then((res) => {
          if (res.code != 0) return this.$message.error(res.msg)
          let urlStr = '?order_sn=' + this.$route.query.order_sn
          this.$api
            .confirmLibrary({
              order_sn: this.$route.query.order_sn,
            })
            .then((res) => {
              if (!res.success) {
                this.zeroInventoryGoodsDialogVisible = true
                return
              }

              if (this.CouponsData.ids) {
                urlStr += '&coupon_ids=' + this.CouponsData.ids
              }
              this.$fun.toPage('/payment' + urlStr)
              setTimeout(() => {
                this.$bus.emit('parameterName', this.newList)
              }, 100)
            })
        })
    },
    // 重新提出
    resubmit() {
      this.datas.detail.forEach((listItem) => {
        listItem.list.forEach((Item) => {
          // 将用户选中的规格匹配中文传回后台 pageSpecification pageSpecificationChinese
          Item.detailChinese = ((
            detailEnglish,
            pageSpecification,
            pageSpecificationChinese
          ) => {
            let detailChinese = []
            detailEnglish.forEach((item, index) => {
              detailChinese.push({
                key: pageSpecificationChinese[index].key,
                value:
                  pageSpecificationChinese[index].value[
                    pageSpecification[index].active
                  ].name,
              })
            })
            return detailChinese
          })(Item.detail, Item.pageSpecification, Item.pageSpecificationChinese)
        })
      })

      this.$api.orderEdit({ data: JSON.stringify(this.datas) }).then((res) => {
        if (res.code == 0) {
          this.$fun.toPage('/user/order?status=all')
        }
      })
    },
    pageLoad() {
      // 判断是购物车还是订单列表跳转的页面
      if (this.$route.query.id) {
        this.orderStatus = 'temporary'
        this.newOrder()
      } else if (this.$route.query.order_sn && this.$route.query.type) {
        this.getData(this.$route.query.order_sn)
      }
      // 获取订单状态
      this.orderStatus = this.$route.query.type
      // 附加服务列表
      this.getOptionList()
    },
    //展示优惠券列表
    showCouponList(e) {
      if (e === false) {
        this.CouponsData.ids = ''
        this.calculateOrder()
      }
      e === true
        ? (this.couponDetailDialogVisible = true)
        : (this.CouponsData.ids = '')
    },
    // 通过订单号获取数据
    getData(order_sn) {
      this.$api
        .orderDetail({
          order_sn: order_sn,
        })
        .then((res) => {
          this.fullscreenLoading = false
          // 如果状态是待报价和接口返回的edit是true就跳到修改订单页面
          if (this.$route.query.type == 'offer' && res.data.edit == true) {
            this.orderStatus = 'editing'
          }
          // 数据赋值

          this.isedit = res.data.edit
          this.datas = res.data

          if (res.data.orderOfferLogistics.length > 0) {
            this.Switchlogistics = res.data.orderOfferLogistics[0].id
          }

          if (res.data.logistics) {
            this.logistics = res.data.logistics
          }

          this.ShippingOrderInformation = res.data.porder_detail
          this.ordno = res.data.ordno
          this.tenshu = res.data.tenshu

          // 循环添加FALSE 控制option 显示
          res.data.detail.forEach((item, index) => {
            item.list.forEach((i, ind) => {
              i.type = true
              i.len = 1
            })
          })

          // 红色状态
          let redstate = []
          // 蓝色状态

          let bluesate = []
          // 正常状态
          let state = []

          res.data.detail.forEach((item, index) => {
            item.list.forEach((i, ind, arr) => {
              if (i.confirm_num === 0 && i.order_detail_replace.length == 0) {
                redstate.push(i)
              } else if (i.order_detail_replace.length !== 0) {
                bluesate.push(i)
              } else if (
                i.order_detail_replace.length == 0 &&
                i.confirm_num > 0
              ) {
                state.push(i)
              }
            })

            item.list = [...redstate, ...bluesate, ...state]
            redstate = []
            bluesate = []
            state = []
          })

          //替换链接数组
          let chainingarray = []
          let newdetail = []
          let arr = []
          res.data.detail.forEach((item, index) => {
            for (let i = 0; i < item.list.length; i++) {
              if (
                item.list[i].confirm_num == 0 &&
                item.list[i].order_detail_replace.length == 0
              ) {
                // 库存不足
                newdetail.push(item)
                return
              } else if (item.list[i].order_detail_replace.length > 0) {
                chainingarray.push(item)
                // 替换链接
                return
              } else if (
                item.list[i].order_detail_replace.length == 0 &&
                item.list[i].confirm_num > 0
              ) {
                arr.push(item)

                return
              }
            }
          })

          res.data.detail = [...newdetail, ...chainingarray, ...arr]
          newdetail = []
          chainingarray = []
          arr = []

          this.newList = res.data.detail

          this.orderPriceGroup = res.data.order_money_group
          this.client_remark = res.data.client_remark
          this.orderStatus = res.data.order_status
          this.status_name = res.data.status_name

          // 如果订单状态为已付款待财务确认，就显示已付款页面
          if (this.orderStatus == 'Pending financial confirmation') {
            this.orderStatus = 'purchased'
          }

          if (res.data.status > 30) {
            let arr = []
            res.data.orderOfferLogistics.forEach((logisticsIt) => {
              if (logisticsIt.logistics_id === res.data.logistics) {
                arr.push(logisticsIt)
              }
            })
            res.data.orderOfferLogistics = arr
          }
          // 调用处理数据的函数
          this.dataProcessing()
        })
    },

    // 打开费用明细弹窗并传值
    openCostBreakdown(id, item) {
      this.CostBreakdownId = id
      this.showCostBreakdown = true
      this.goodsAct = item
    },
    /*********************************************************************************************** */
    // 点击页面时关闭所有弹窗
    bodyCloseMenus(e) {
      this.transportationControl.show = false
      if (e.target.className != 'detail fujiafuwu') {
        this.showPriceDetail = false
      }
      if (
        ['additionalServices', 'inputOption'].indexOf(e.target.className) == -1
      ) {
        this.newList.forEach((item) => {
          item.list.forEach((jtem) => {
            jtem.accessorialService = false
            this.$forceUpdate()
          })
        })
      }
      if (
        ['detailWindowBody', 'goodsDetailOpt'].indexOf(e.target.className) == -1
      ) {
        this.newList.forEach((item) => {
          item.list.forEach((jtem) => {
            jtem.detailWindow = false
            this.$forceUpdate()
          })
        })
      }
    },
    // 追加商品,传入参数：添加的商品数据
    addTo(addGoodsData) {
      // 如果是待支付状态的订单，就把追加商品的价格转换成报价价格字段
      if (this.oldobligation == 'obligation') {
        addGoodsData.newList.forEach((shopitem, shopindex) => {
          shopitem.list.forEach((goodsitem, goodsindex) => {
            // 从商品库添加的商品如果数量为0就把他改为1
            if (goodsitem.num == 0) {
              goodsitem.num = 1
            }

            goodsitem.confirm_price = goodsitem.price
            goodsitem.confirm_num = goodsitem.num
          })
        })
      }
      this.addToDataProcessing(addGoodsData)
    },
    // 追加商品数据处理
    addToDataProcessing(addData) {
      // 将追加的商品数据放入当前列表
      addData.newList.forEach((item) => {
        // 判断是否是同一店铺，是的话将商品加入同一店铺下,因为不同种类id不同所以不用判断种类
        let isSame = false
        this.newList.forEach((jtem) => {
          if (item.shop_name == jtem.shop_name) {
            item.list.forEach((etem) => {
              jtem.list.push(etem)
              isSame = true
            })
          }
        })
        if (isSame == false) {
          this.newList.push(item)
        }
      })
      this.calculateOrder()
      this.dataProcessing()
    },

    // 删除商品
    delOrder() {
      if (this.checked) {
        this.newList = []
        this.checked = false
        this.dataProcessing()
        // 提交后端计算价格
        this.calculateOrder()
        this.$message({
          message: this.$fanyi('删除成功'),
          type: 'success',
        })
        return
      }

      if (
        this.newList
          .reduce((acc, item) => {
            acc = [...acc, ...item.list]
            return acc
          }, [])
          .every((i) => i.checked !== true)
      ) {
        this.$message(this.$fanyi('请至少选择一个商品'))
        return
      }
      for (let a = 0; a < this.newList.length; a++) {
        for (let b = 0; b < this.newList[a].list.length; b++) {
          // 如果商品数组的checked是true就删除商品和$route.query.id里的cart_id

          if (this.newList[a].list[b].checked) {
            this.newList[a].list.splice(b, 1)
            b--
            // 如果店铺的list数组没有数据了就删除店铺
            if (this.newList[a].list.length == 0) {
              this.newList.splice(this.newList.indexOf(this.newList[a]), 1)
              a--
            }
            this.dataProcessing()
            // 提交后端计算价格
            this.calculateOrder()

            this.$message({
              message: this.$fanyi('删除成功'),
              type: 'success',
            })
          }
        }
      }
    },
    //删除订单
    delObligationOrder() {
      this.$api
        .orderDelete({
          order_sn: this.$route.query.order_sn,
        })
        .then((res) => {
          if (res.code != 0) return
          this.zeroInventoryGoodsDialogVisible = false
          this.$fun.toPage('/user/order?status=all')
        })
    },
    // 提交或临时保存
    OrdersSubmitted(type) {
      if (this.newList.length == 0) {
        return this.$message(this.$fanyi('订单里请至少添加一款商品'))
      } else if (this.newList.length > 60) {
        return this.$message(
          this.$fanyi('单个订单不能超过60番产品，请调整提出番数')
        )
      }
      let dataList = JSON.parse(JSON.stringify(this.datas))

      this.newList.forEach((listItem) => {
        listItem.list.forEach((Item) => {
          // 将用户选中的规格匹配中文传回后台 pageSpecification pageSpecificationChinese
          Item.detailChinese = ((
            detailEnglish,
            pageSpecification,
            pageSpecificationChinese
          ) => {
            let detailChinese = []
            detailEnglish.forEach((item, index) => {
              detailChinese.push({
                key: pageSpecificationChinese[index].key,
                value:
                  pageSpecificationChinese[index].value[
                    pageSpecification[index].active
                  ].name,
              })
            })
            return detailChinese
          })(Item.detail, Item.pageSpecification, Item.pageSpecificationChinese)

          if (Item.option == '') {
            Item.option = []
          }
          if (typeof Item.price_range == 'object') {
            Item.price_range = JSON.stringify(Item.price_range)
          }
          if (typeof Item.goods_inventory == 'object') {
            Item.goods_inventory = JSON.stringify(Item.goods_inventory)
          }
          if (typeof Item.specification == 'object') {
            Item.specification = JSON.stringify(Item.specification)
          }
        })
      })

      dataList.detail = this.newList
      dataList.logistics = this.logistics
      let datas = {
        status: type,
        data: JSON.stringify(dataList),
        client_remark: this.client_remark,
      }
      this.$api.orderCreate(datas).then((res) => {
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg))
        // 假如点击的是临时保存按钮
        if (type == 10) {
          this.$fun.toPage('/user/order?status=temporary')
        }
        // 假如点击的是提出按钮
        if (type == 20) {

          // 进行判断埋点
          if (this.$fun.isOfficialWeb()) {

            this.newList.forEach((item) => {
              item.list.forEach((ite) => {
                if (ite.trace) {
                  if (ite.trace == 'linkSearch') {
                    __bl.sum('通过链接搜索进入商品详情且加入购物车提出订单数量统计');
                  }
                  if (ite.trace == 'keywordSearch') {
                    __bl.sum('通过关键词搜索进入商品详情且加入购物车提出订单数量统计');

                  }
                  if (ite.trace == 'imgSearch') {
                    __bl.sum('通过图片搜索进入商品详情且加入购物车提出订单数量统计');
                  }
                  if (ite.trace == 'classificationSearch') {
                    __bl.sum('通过分类搜索进入商品详情且加入购物车提出订单数量统计');
                  }
                  if (ite.trace == 'taobaolinkSearch') {
                    __bl.sum('通过淘宝链接搜索进入商品详情且加入购物车提出订单数量统计');
                  }
                  if (ite.trace == '1688linkSearch') {
                    __bl.sum('通过1688链接搜索进入商品详情且加入购物车提出订单数量统计');
                  }
                  if (ite.trace == 'tmalllinkSearch') {
                    __bl.sum('通过天猫链接搜索进入商品详情且加入购物车提出订单数量统计');
                  }
                  if (ite.trace == 'rakumartlinkSearch') {
                    __bl.sum('通过rakumart链接搜索进入商品详情且加入购物车提出订单数量统计');
                  }

                }
              })
            })
          }
          // this.$fun.toPage("/user/order?status=offer");
          this.placingSuccessfully = true

          this.$nextTick(() => {
            this.$refs.querenyemian.getBuyGoodsArr()
          })
          // this.placingSuccessfully = true;
          this.$store.dispatch('sidebarNumList')
        }
      })
    },
    // 打开商品详细弹窗
    openDetailWindow(item) {
      this.newList.forEach((item) => {
        item.list.forEach((jtem) => {
          jtem.detailWindow = false
          this.$forceUpdate()
        })
      })
      item.detailWindow = !item.detailWindow
      this.$forceUpdate()
    },
    // 计算订单金额
    calculateOrder() {
      this.quantityOfCommodity = 0
      let newList = JSON.parse(JSON.stringify(this.newList))
      let datas = []
      for (let i in newList) {
        for (let j in newList[i].list) {
          if (newList[i].list[j].option == '') {
            newList[i].list[j].option = []
          }

          if (this.orderStatus == 'temporary') {
            this.quantityOfCommodity += newList[i].list[j].num
          } else if (
            [
              'obligation',
              'offer',
              'delete',
              'editing',
              'temporary',
              '"purchased"',
            ].indexOf(this.orderStatus) == -1
          ) {
            this.quantityOfCommodity += newList[i].list[j].purchase_num
          } else {
            this.quantityOfCommodity += newList[i].list[j].confirm_num
          }

          datas.push(newList[i].list[j])
        }
      }

      datas = JSON.stringify(datas)
      this.fullscreenLoading = true

      this.$api
        .calculateOrder({ data: datas, coupon_ids: this.CouponsData.ids })
        .then((res) => {
          this.fullscreenLoading = false
          if (res.code != 0) {
            this.youHuiQuanShiYong = false
            this.CouponsData.ids = ''
            return this.$message.error(this.$fanyi(res.msg))
          }
          this.orderPriceGroup = res.data
        })
    },
    // 获取附加服务列表
    getOptionList() {
      this.$api.optionList().then((res) => {
        this.optionList = res.data
      })
    },
    // 设置附加服务-重置

    optionReact(item) {
      item.option_old.forEach((i) => {
        i.checked = false
        i.num = 0
        delete i.fba
      })
      this.oneGoodsOptionTotalPrice = 0
      let checkOptionBox = document.getElementsByClassName('checkOptionBox')
      for (let i = 0; i < checkOptionBox.length; i++) {
        checkOptionBox[i].checked = false
      }
    },
    // 选中附加服务时将商品数量填进附加服务数量
    setOptionsNum(optionItem, goodsNum) {
      if (optionItem.checked) {
        if (optionItem.name == '摄影') {
          optionItem.num = 1
        } else {
          optionItem.num = goodsNum
        }
      } else {
        optionItem.num = 0
      }
    },
    // 设置附加服务-保存选择的附加服务
    saveOrderOption(item, index) {
      if (item.option_old.every((ite) => ite.checked == false)) {
        item.option = []
      } else {
        item.option = JSON.parse(JSON.stringify(item.option_old))
      }

      // for (let i = 0; i < item.option_old.length; i++) {
      //   item.option[i].checked = item.option_old[i].checked;
      //   item.option[i].num = item.option_old[i].num;
      // }

      item.accessorialService = false
      this.calculateOrder()

      this.$refs[`popoveraite`][index - 1].doClose()
      this.$forceUpdate()
    },

    // 设置附加服务-打开弹窗并初始化数据和选中已选附加服务
    setOrderOption(item) {
      // 初始化数据
      item.accessorialService = !item.accessorialService
      this.checkItem = item

      this.setOneGoodsOptionTotalPrice(item, item.num)
      this.$forceUpdate()
      // this.showOptionList = true;
    },
    // 订单预览
    newOrder(ids) {
      if (ids) {
        var datas = {
          cat_id: ids,
        }
      } else {
        if (this.$route.query.iscomm) {
          var datas = {
            inventory_id: this.$route.query.id,
          }
        } else {
          var datas = {
            cat_id: this.$route.query.id,
          }
        }
      }
      if (this.$route.query.iscomm) {
        this.ids = datas.inventory_id.split(',')
      } else {
        this.ids = datas.cat_id.split(',')
      }

      this.$api.orderPreview(datas).then((res) => {
        this.fullscreenLoading = false
        this.datas = res.data
        this.ordno = res.data.ordno
        this.tenshu = res.data.tenshu

        this.newList = res.data.detail

        if (res.data.logistics) {
          this.logistics = res.data.logistics
        }

        this.orderPriceGroup = res.data.order_money_group
        // 调用弹出相应提示函数
        // this.$refs.customerPrompt.openOrderDia();
        // this.$refs.whyDia.open();
        // this.value = "9";
        this.dataProcessing()
      })
    },
    // 数据处理
    dataProcessing() {
      this.sorting = 1
      let quantityOfCommodity = 0

      for (let i in this.newList) {
        for (let j in this.newList[i].list) {
          if (this.newList[i].list[j].num == 0) {
            this.newList[i].list[j].num = 1
          }

          this.newList[i].list[j].optionSelectStatus = false
          this.newList[i].list[j].showOption = false

          if (
            this.newList[i].list[j].confirm_num === 0 &&
            this.datas.order_status === 'obligation'
          ) {
            this.zeroInventoryGoods++
          }
          // 如果接口没有返回商品番号,就自己排一个番号给商品
          if (this.orderStatus == 'temporary') {
            this.newList[i].list[j].sorting = this.sorting
          }
          this.sorting++

          // 总价计算
          if (
            this.newList[i].list[j].confirm_num &&
            this.newList[i].list[j].confirm_num > 0
          ) {
            this.newList[i].list[j].totalPrice = Number(
              this.$fun.ceil(
                this.newList[i].list[j].confirm_price *
                this.newList[i].list[j].confirm_num
              )
            )
          } else {
            this.newList[i].list[j].totalPrice = Number(
              this.$fun.ceil(
                this.newList[i].list[j].price * this.newList[i].list[j].num
              )
            )
          }
          // this.newList[i].list[j].totalPrice = (
          //   this.newList[i].list[j].num * this.newList[i].list[j].price
          // ).toFixed(2);
          // 拿一份页面使用时使用的商品所有规格数据
          if (!!this.newList[i].list[j].specification) {
            if (typeof this.newList[i].list[j].specification != 'object') {
              this.newList[i].list[j].pageSpecification = JSON.parse(
                this.newList[i].list[j].specification
              )
              this.newList[i].list[j].pageSpecificationChinese = JSON.parse(
                this.newList[i].list[j].specificationChinese
              )
            } else {
              this.newList[i].list[j].pageSpecification = this.newList[i].list[
                j
              ].specification
              this.newList[i].list[j].pageSpecificationChinese = this.newList[
                i
              ].list[j].specificationChinese
            }

            let skuPropsChinese = []

            Object.keys(
              this.newList[i].list[j].pageSpecificationChinese
            ).forEach((key) => {
              skuPropsChinese.push({
                key: key.substr(1, key.length - 2),
                value: this.newList[i].list[j].pageSpecificationChinese[key],
                active: 0,
              })
            })

            this.newList[i].list[j].pageSpecificationChinese = skuPropsChinese
          }

          let skuProps = []
          Object.keys(this.newList[i].list[j].pageSpecification).forEach(
            (key) => {
              // 英文属性
              let enshuXing = this.newList[i].list[j].specification[key]
              // 选中商品下标
              let active = 0
              // 选中的属性
              let xuanZhongShuXing = this.newList[i].list[j].detail
              xuanZhongShuXing.forEach((xuanZhongitem, xuanZhongindex) => {
                enshuXing.forEach((enshuXingItem, enshuXingIndex) => {
                  if (enshuXingItem.name == xuanZhongitem.value) {
                    active = enshuXingIndex
                  }
                })
              })
              skuProps.push({
                key: key.substr(1, key.length - 2),
                value: this.newList[i].list[j].pageSpecification[key],
                active: active,
              })
            }
          )
          this.newList[i].list[j].pageSpecification = skuProps

          this.newList[i].list[j].detailWindow = false
          this.newList[i].list[j].accessorialService = false
          if (this.checked) {
            this.newList[i].list[j].checked = true
          } else {
            this.newList[i].list[j].checked = false
          }

          if (this.orderStatus == 'temporary') {
            quantityOfCommodity += this.newList[i].list[j].num
          } else if (
            [
              'obligation',
              'offer',
              'delete',
              'editing',
              'temporary',
              'purchased',
            ].indexOf(this.orderStatus) == -1
          ) {
            quantityOfCommodity += this.newList[i].list[j].purchase_num
          } else {
            quantityOfCommodity += this.newList[i].list[j].confirm_num
          }

          if (!this.newList[i].list[j].option) {
            this.newList[i].list[j].option = []
          }

          // 假如返回的附加服务列表是字符串，就转化成空数组
          // if (this.newList[i].list[j].option == '"[]"') {
          //   this.newList[i].list[j].option = []
          // } else if (!this.newList[i].list[j].option) {
          //   this.newList[i].list[j].option = []
          // } else {
          //   console.log(this.newList[i].list[j].option)
          //   this.newList[i].list[j].option = JSON.parse(
          //     this.newList[i].list[j].option
          //   )
          //   console.log(this.newList[i].list[j].option)
          // }
          // console.log(this.newList[i].list[j].option)
          // 如果没有option，就添加option
          // if (!this.newList[i].list[j].option) {

          // }
          // // 把接口返回的附加服务列表字符串转换为数组
          // if (!!this.newList[i].list[j].option &&
          //   typeof this.newList[i].list[j].option != "object") {
          //   this.newList[i].list[j].option = JSON.parse(this.newList[i].list[j].option);
          // }
          // 附加服务
          // let lisopt = this.newList[i].list[j].option
          // for (let u = 0; u < lisopt.length; u++) {
          //   if (!this.newList[i].list[j].option[u].checked) {
          //     lisopt.splice(u, 1)
          //     u--
          //   } else {
          //     this.newList[i].list[j].optionSelectStatus = true
          //   }
          // }
        }
      }
      this.quantityOfCommodity = quantityOfCommodity
    },

    getTotalPrice(item) {
      // 如果有人工报价价格和人工报价数量，就使用人工报价和人工报价数量计算总价
      if (item.confirm_num && item.confirm_num > 0) {
        // 计算总价时防止出现小数位过多情况限制最多显示三位小数
        item.totalPrice = this.$fun.ceil(item.confirm_price * item.confirm_num)
      } else {
        item.totalPrice = this.$fun.ceil(Number(item.price) * Number(item.num))
      }

      this.calculateOrder()
    },
    // 单选
    radio(i) {
      let flag = this.newList[i].list.every((item) => item.checked == true)
      if (flag) {
        this.newList[i].checked = true
      } else {
        this.newList[i].checked = false
      }
      this.isCheckedAll()
      this.$forceUpdate()
    },
    shopAllSelect(row, index) {
      row.checked = !row.checked
      this.shopAll(index)
    },
    // 店铺全选
    shopAll(i) {
      if (this.newList[i].checked) {
        this.newList[i].list.forEach((item) => {
          item.checked = true
        })
      } else {
        this.newList[i].list.forEach((item) => {
          item.checked = false
        })
      }
      this.$forceUpdate()
      this.isCheckedAll()
    },
    // 全选
    allChoice() {
      if (this.checked) {
        this.newList.forEach((item, index) => {
          item.checked = true
          this.shopAll(index)
        })
      } else {
        this.newList.forEach((item, index) => {
          item.checked = false
          this.shopAll(index)
        })
      }
      this.$forceUpdate()
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.newList.every((item) => item.checked == true)
      if (flag == true) {
        this.checked = true
      } else {
        this.checked = false
      }
      this.$forceUpdate()
    },
    //关闭弹窗
    cloneDialogVisible(msg) {
      this.couponDetailDialogVisible = msg
      this.couponChecked = msg
    },
    //关闭商品金额变更明细弹窗
    cloneGoodsPriceUpdateDetailDialogVisible(msg) {
      this.goodsPriceUpdateDetailDialogVisible = msg
    },

    //将选择的优惠券后的价格重新计算
    getActiveCoupon(msg, flag) {
      this.CouponsData.ids = msg
      this.couponChecked = flag
      this.couponDetailDialogVisible = false
      this.calculateOrder()
    },
  },
}
</script>

<style lang="scss">
.el-popup-parent--hidden {
  padding-right: 0 !important;
}

.el-popover.el-popper.dditionalServicesPro {
  background-color: transparent;
  box-shadow: none;
  border: none;

  .popper__arrow {
    display: none;
  }
}

.addtionalPopover {
  top: 440px !important;
  left: 404px !important;
}

button {
  cursor: pointer;
}

.el-message-box__btns {
  text-align: center;

  .el-button {
    width: 100px;

    &:first-child {
      margin-right: 30px;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '../../../../../css/mixin.scss';

// /deep/ .el-popover {
//   min-width: 180px !important;
//   background-color: pink;
// }
/deep/ .el-dialog__headerbtn {
  top: 10px;
}

/deep/.el-input-number {
  overflow: hidden !important;

  .el-input-number__decrease {
    background: #f2f2f2;

    overflow: hidden !important;
  }
}

/deep/ .el-input-number__increase {
  background: #f2f2f2;
  overflow: hidden;
  border-radius: 0px 4px 4px 0px;
}

/deep/.el-input-number__decrease:active,
/deep/.el-input-number__increase:active {
  background-color: #ff730b;

  i {
    color: white;
  }
}

/deep/.el-dialog {
  border-radius: 6px !important;
}

.orderPage {
  background-color: #f6f6f6;
}

.main {
  border-radius: 6px;
  // border: 1px solid #e2e2e2;
}

/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0;
  border-radius: 6px;
}

/deep/ .el-dialog__footer {
  padding: 0 30px 30px;
}

.delBtn {
  width: 157px;
  height: 30px;
  padding-left: 10px;
  line-height: 30px;
  background: #fff;
  margin-bottom: 15px;
}

.addgoods {
  width: 157px;
  height: 30px;
  background: #fff;
  padding-left: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  border-radius: 4px;
}

.addLibraryOfGoods {
  min-width: 180px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  background: #fff;
}

.addLibrary {
  min-width: 100px;
  height: 40px;
  background: #608bff;
  padding: 0 20px !important;
  text-align: center;
  color: #fff !important;
  border-radius: 4px;
}

.delBtn:hover {
  width: 157px;
  height: 30px;
  background: #eff4ff;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 10px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  margin-bottom: 15px;

  color: #608bff;
}

.addgoods:hover {
  width: 157px;
  height: 30px;
  background: #eff4ff;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 10px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  line-height: 30px;
  color: #608bff;
}

.addLibraryOfGoods:hover {
  min-width: 180px;
  height: 30px;
  background: #eff4ff;
  border-radius: 4px;
  padding-left: 10px;
  line-height: 30px;

  font-size: 14px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #608bff;
}

.Opt {
  width: 100%;

  height: 80px;
  display: block;
  border-radius: 4px;
  overflow-y: auto;
  background-color: #fafafa;
  border: 1px solid #ededed;
  color: #a7a7a7;

  .list {
    text-align: left !important;
    padding-left: 10px;
  }
}

.inputOption {
  width: 100%;
  height: 80px;
  max-height: 80px;
  overflow-y: auto;
  display: flex;

  justify-content: space-between;
  align-items: center;

  &.haveData {
    background-color: white;
    border: 1px solid #ededed;
    color: #a7a7a7;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .placeHole {
    background-color: white;
    border: 1px solid #ededed;
    text-align: left;
    width: 140px;
    padding: 10px;
    line-height: 12px;
    position: relative;

    i {
      position: absolute;
      right: 5px;
      top: 11px;
      font-size: 12px;
      z-index: 0;
    }
  }

  .placeTiShi {
    color: #a7a7a7;
    font-size: 11px;
    word-break: break-all;
    text-align: left;
  }

  .list {
    text-align: left;
    padding-left: 10px;
  }
}

.main.zhu {
  padding: 20px 20px 50px 20px;
  background: #ffffff;
  border-radius: 6px;
  position: relative;
  font-size: 14px;

  .neirong {
    height: 300px;
    overflow: hidden;
  }

  &.AllGoods {
    min-height: auto !important;
    transition: all 0.5s;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    span {
      font-size: 14px;
      font-weight: 400;
      font-family: 'Open Sans', Sans-serif;
      color: #2f2f2f;
      line-height: 16px;

      .headT1 {
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 600;
      }
    }

    .order_snBox {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;

      .time {
        font-size: 14px !important;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #666666 !important;
      }
    }

    .editOrder {
      width: 120px;
      height: 32px;
      background: #ff730b;
      border-radius: 4px;
      font-size: 14px;
      height: 32px;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #ffffff;
      margin-left: 20px;
    }

    .addshopLibrary {
      margin-left: 20px;
      min-width: 100px;
      height: 32px;
      background: #ff730b;
      padding: 0 15px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      border-radius: 4px;
      text-align: center;
      line-height: 32px;
    }
  }

  .order {
    font-family: Roboto-Regular, Roboto;

    &.orderfixedBox {
      padding-top: 56px;
    }

    // 同时设置表头和内容宽度
    ul {
      display: flex;
    }

    li {
      flex: 1;
      text-align: center;
      font-weight: 600;

      .ViewProducts {
        width: 146px;
        height: 34px;
        background: #eff4ff;
        border-radius: 4px;
        border: 1px solid #608bff;
        line-height: 34px;
        text-align: center;
        font-size: 12px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #608bff;
      }

      .Replacementproduct {
        width: 146px;
        height: 34px;
        background: #608bff;
        border-radius: 4px;
        font-size: 12px;
        // margin-right: 40px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 34px;
      }

      // 全选
      &.inputBox {
        flex: 1.4;
        display: flex;
        align-items: center;

        .headCheckBox {
          margin-top: 0;
          margin-right: 10px
        }

        >.rowNumber {
          width: 60px;
          display: inline-block;
        }

        >* {
          margin-top: -12px;
        }
      }

      // 产品
      &.goods {
        flex: 0.9;
        padding: 20px;
        box-sizing: border-box;
        text-overflow: -o-ellipsis-lastline;

        .showGoodsTitle {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          font-weight: normal;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }

      &.goodsDetail {
        height: 90px;
        overflow-y: auto;
      }

      // 详细
      &.detail {
        width: 160px;
        padding: 0 12px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;

        // background-color: pink;
        &::-webkit-scrollbar {
          width: 3px;
          height: 1px;
        }

        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
          background: #f2f2f2;
        }

        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
      }

      // 单价
      &.price {
        width: 160px;
        display: flex;
        justify-content: center;
        align-self: center;
      }

      // 数量
      &:nth-child(5) {
        width: 140px;
      }

      // 总价
      &.total {
        width: 80px;
      }

      // 附加服务

      // 备注
      &.remark {
        width: 200px;
      }

      &.zhongGuoSuoYouFeiYong {
        flex: 0.94 !important;
        box-sizing: content-box;
        // padding-right: 10px;

        &.head {
          // background: #fff5ee;
          flex: 0 0 130.66px;

          >div {
            display: inline;
            width: 128px;
            line-height: 14px;
          }
        }

        &.body1 {
          padding: 20px 0;

          >div {
            width: 100%;
            height: 100%;
            background: #fff5ee;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #000000;
            font-size: 14px;
            line-height: 20px;

            .mingXi {
              display: block;
              margin-top: 10px;
              width: 100px;
              height: 30px;
              background: #eff4ff;
              border-radius: 4px;
              border: 1px solid #608bff;
              font-weight: 600;
              color: #608bff;
              line-height: 30px;
              cursor: pointer;
            }
          }
        }
      }

      &.yuGuDaoShouFeiYong {
        height: 115px;

        &.head {
          background: #fff5ee;
        }

        &.body1 {
          padding: 20px 0;

          >div {
            width: 100%;
            height: 100%;
            // background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #000000;
            font-size: 14px;

            line-height: 20px;

            .mingXi {
              margin-top: 10px;
              display: block;
              font-size: 12px;
              width: 100px;
              height: 30px;

              line-height: 30px;
              background: #e9f8fb;
              border-radius: 4px;
              border: 1px solid #49c9e0;
              height: 30px;

              font-size: 12px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;
              cursor: pointer;
            }

            .warehouse {
              height: 30px;
              background: #e4fbf4;
              border-radius: 4px;
              border: 1px solid #1ba37b;
              font-size: 12px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;
            }
          }
        }
      }

      &.constDetail {

        // display: flex;
        // align-items: center;
        // justify-content: center;
        // height: 95px;
        >textarea {
          width: 90%;
          border: 1px solid #e4e7ed;
          height: 80px;
          border-radius: 0px;
          font-size: 12px;
          line-height: 25px;

          padding-left: 10px;

          &::placeholder {
            color: #a7a7a7;
            text-align: left;
            padding: 10px;
            padding-left: 5px;
            font-size: 12px;
            line-height: 18px;
          }
        }

        div {
          margin-top: 20px;
        }
      }

      &.viewdetails {

        height: 115px;

        div {
          p {
            width: 110px;
            height: 34px;
            background: #eff4ff;
            border-radius: 4px;
            border: 1px solid #608bff;
            font-size: 12px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #608bff;
            line-height: 34px;
          }
        }
      }
    }

    // 内容里的ul样式
    .goodsBox {
      overflow: hidden;

      >div {
        margin-top: 10px;
        border-bottom: solid 9px transparent;

        // position: relative;
        &:after {
          // position: absolute;
          left: -20px;
          bottom: -9px;
          content: '';
          background-color: #f1f1f1;
          width: 1400px;
          height: 9px;
        }

        >h1 {
          display: flex;
          align-items: center;
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 14px;
          color: #000000;
          line-height: 20px;

          .checkedBox {
            // width: 55px;
            height: 100%;
            display: flex;
            align-items: center;

            >input {
              margin-right: 16px;
              margin-left: 20px;
            }
          }

          .img1688box {
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: 10px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }

        >.goodsCon {
          border-bottom: 1px dashed #e2e2e2;

          .bordercolor {
            background: #fdf3f2 !important;
            border-radius: 6px !important;
            border: 1px solid #f74c3c;
            position: relative;

            .dingwei {
              position: absolute;

              top: 0;
              padding: 0px;
              left: 0;
              padding: 0 4px;
              max-width: 124px;
              height: 20px !important;

              line-height: 20px;
              background: #f74c3c;
              font-size: 12px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #ffffff;
              border-radius: 5px 0px 6px 0px;
            }
          }

          .borderblue {
            background: #eff4ff !important;
            border-radius: 6px;
            border: 1px solid #608bff;
            position: relative;

            .dingwei {
              position: absolute;

              top: 0;
              padding: 0px;
              left: 0;
              padding: 0 4px;
              max-width: 124px;
              height: 20px !important;

              line-height: 20px;
              background: #608bff;
              font-size: 12px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #ffffff;
              border-radius: 5px 0px 6px 0px;
            }
          }

          .goodsDetailCon,
          >ul {
            background: white;
            height: 100px;

            &:hover {
              background: #f9f9f9;
            }

            >li {
              line-height: 20px;
              display: flex;
              align-items: center;
              padding-top: 10px;
              justify-content: center;
              flex-wrap: wrap;

              // 全选
              &.inputBox {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                input {
                  margin-left: 20px;
                }

                img {
                  width: 80px;
                  height: 80px;
                  margin-left: 10px;
                  cursor: pointer;
                }
              }

              // 产品
              &.goods {
                cursor: pointer;

                text-align: left;
              }

              // 详细
              &:nth-child(3) {
                cursor: pointer;
                position: relative;
                box-sizing: border-box;
                text-align: left;

                &.active {
                  background-color: white;
                }

                >div {
                  width: 100%;
                }

                img {
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  top: 20px;
                  right: 28px;
                }
              }

              // 总价
              &.total {
                font-size: 14px;
                color: #ff730b;
                line-height: 19px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              // 附加服务
              &.options {
                flex: 0 0 160px;

                .obligationOptionInput {
                  width: 140px;
                  height: 80px;
                  background: #f9f9f9;
                  border-radius: 4px;
                  border: 1px solid #ededed;
                  overflow-y: auto;
                  padding: 6px 10px;
                  // text-align: left;
                  // background-color: pink;
                }

                .optList {
                  color: black;

                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  height: 20px;
                  font-size: 12px;
                  font-weight: normal;
                  display: flex;
                  justify-content: flex-start;
                  width: 100px;
                }

                .opi {
                  font-size: 12px;
                  display: flex;
                  justify-content: flex-start;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                }

                .optionInput {
                  width: 140px;
                  height: 80px;
                  border-radius: 0px;
                  max-height: 80px;
                  position: relative;

                  .list {
                    text-align: left;
                    padding-left: 10px;
                  }

                  cursor: pointer;
                }
              }

              // 备注
              &.remark {
                >textarea {
                  width: 90%;
                  border: 1px solid #e4e7ed;
                  height: 80px;
                  border-radius: 0px;
                  font-size: 12px;
                  line-height: 25px;

                  padding-left: 10px;

                  &::placeholder {
                    color: #a7a7a7;
                    text-align: left;
                    padding: 10px;
                    padding-left: 5px;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }

                .beizhu {
                  width: 140px;
                  height: 80px;
                  background: #f9f9f9;
                  border-radius: 4px;
                  overflow-y: auto;
                  border: 1px solid #ededed;
                  text-align: left;
                  word-break: break-all;
                  font-weight: normal;
                  padding: 6px 10px;
                }
              }

              &.constDetail {
                // margin-top: 20px !important;

                // background-color: pink;
                button {
                  font-size: 12px;
                  color: #608bff;
                  line-height: 19px;

                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    // 设置表头
    >ul {
      background-color: #f9f9f9;
      height: 56px;
      line-height: 32px;
      z-index: 5;
      position: sticky;
      top: 64px;

      &.fixedBox {
        position: fixed;
        top: 168px;
        width: 1360px;
      }

      &.shang {
        top: 104px;
      }

      >li {
        font-size: 12px;
        line-height: 20px;
        display: flex;
        height: 56px;
        align-items: center;
        justify-content: center;

        &:first-child {
          justify-content: flex-start;
        }
      }
    }

    ul {
      display: flex;
      line-height: 70px;
      font-size: 12px;

      input {
        margin-left: 20px;
      }

      .pic {
        position: relative;

        .upload-image {
          position: absolute;
          top: 0;
          left: 60px;

          .upload-demo {
            position: absolute;
            top: 12px;
            left: 0;
            width: 30px;
            height: 28px;
            // opacity: 0;
          }
        }
      }

      .img {
        position: relative;
        width: 40px;
        height: 50px;
        z-index: 1;

        &:hover .bigImg {
          display: block;
        }

        img {
          width: 40px;
          height: 50px;
          margin-top: 10px;
        }

        .bigImg {
          display: none;
          position: absolute;
          top: 60px;
          left: 50px;

          img {
            margin: 0;
            width: 300px;
            height: 300px;
          }
        }
      }

      .num {
        // display: flex;
        // justify-content: center;
        // align-items: center;

        input {
          width: 50px;
        }
      }

      .beikao {
        textarea {
          margin-top: 3%;
          width: 85%;
          height: 70%;
          padding: 5px;
          resize: none;
          border: 1px solid #cccc;
        }
      }

      .option {
        position: relative;
        overflow: hidden;
        height: 100%;

        p {
          width: 80%;
          line-height: 23px;
          text-align: left;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;

          .optionNum {
            margin-left: 5px;
            color: #5bb578;
          }
        }
      }

      i {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 14px;
        color: #666;
        z-index: 1;
        cursor: pointer;
      }
    }
  }

  .showAllGoods {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    transition: all 0.5s;
    background: #ffffff;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 6px 6px;
    border: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    span {
      font-size: 14px;
      font-weight: 600;
      color: #222222;
      line-height: 24px;
      margin-right: 10px;
    }

    // &.openBtn {
    //   position: fixed;
    //   bottom: 202px;
    //   width: 1400px;
    //   margin: 0 auto;
    //   left: 0;
    //   right: 0;
    // }

    img {
      width: 24px;

      margin-right: 24px;
      cursor: pointer;
    }
  }
}

//提出订单的样式
.main.submit_box {
  .order {
    .submit_box_header {
      height: 48px !important;

      li {
        height: 48px !important;
      }
    }


  }
}

// 订单详情状态页面
.dingDanXiangQing {
  .xiangqingCon {
    ul {
      background-color: #fff !important;
    }
  }

  .goodsDetailOrder {
    margin-top: 10px;
    padding-left: 20px;

    .optionsBox {
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;

      .optionsTitle {
        font-size: 14px;
        color: #666666;
        margin-right: 10px;
      }

      .allOptions {
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;

        .tag {
          position: relative;
          bottom: 2px;
        }
      }

      .lookAllOptions {
        margin-left: 10px;
        font-size: 14px;
        color: #888888;
        cursor: pointer;
      }
    }

    // 附加服务
    .Additionalservice {
      position: relative;
      padding-right: 50px;
      width: 220px;
      height: 20px;
      overflow: hidden;
      margin-bottom: 10px;

      .AdditionalserviceTitle {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #999999;
      }

      .Additionalservicebody {
        span {
          line-height: 15px;
        }
      }
    }

    .AdditionalserviceTitle {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #999999;
    }

    // 附加服务
    .service {
      display: flex;
    }

    .Additional {
      position: relative;

      width: 220px;
      height: auto;
      transition: all 0.5s;

      .item {
        display: inline-block;
        text-align: left;
      }
    }

    .remarkCon {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      margin-bottom: 10px;

      .remarkTitle {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #999999;
      }
    }

    .all {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #ff730b;
      line-height: 20px;

      cursor: pointer;
    }

    .huiFu {
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      margin-bottom: 10px;

      .huiTitle {
        color: #666666;
        min-width: 126px;
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

// 附加服务选框样式
.additionalServices {
  border-radius: 0px;
  // border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
  // width: 900px;
  background-color: white;

  width: 860px;
  max-height: unset;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #e2e2e2;
  // background-color: pink;
  border-radius: 6px;
  padding: 10px;

  >p {
    display: flex;
    align-items: center;

    >span {
      display: block;
      text-align: center;

      // 附加服务选项
      &:first-child,
      .optionsCheck {
        width: 300px;
      }

      // 数量
      &:nth-child(2),
      .optionsNum {
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;

        input {
          margin: 0;
        }
      }

      // fba
      &:nth-child(3),
      .optionsFba {
        width: 120px;

        input {
          margin: 0;
        }
      }

      // 预计费用
      &:nth-child(4),
      .optionsPrice {
        width: 140px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis !important;
        -o-text-overflow: ellipsis;
      }
    }

    // 头部单独样式
    &.ohead {
      margin-bottom: 10px;

      span {
        font-size: 12px;

        font-weight: bold;
        color: #000000;
        line-height: 16px;

        // margin: 0px 0px 16px;
        &:first-child {
          text-align: left;
        }
      }
    }

    // 内容单独样式
    &.obody {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      >span {
        display: flex;
        align-items: center;
      }

      // input[type="checkbox"] {
      //   width: 16px;
      //   height: 16px;
      //   margin: 0;
      //   background: #ffffff;
      //   border-radius: 3px;
      //   border: 1px solid #888888;
      // }
      .num {
        margin-right: 10px;

        input {
          width: 60px;
          height: 22px;
          border-radius: 0px;
          text-align: center;
          border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
        }
      }

      .fba {
        display: inline-block;
        width: 100px;
        margin-right: 15px;
        width: 130px;
        position: relative;

        textarea {
          position: absolute;
          top: -20px;
          left: 0;
          width: 130px;
          height: 42px;
          font-size: 12px;
          border-radius: 0px;
          padding: 10px;
          border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
        }
      }

      .price {
        display: flex;
        // justify-content: center;
        width: 120px;
        align-items: center;
      }
    }
  }

  .foot {
    text-align: right;
    display: flex;
    justify-content: flex-end;

    >p {
      font-size: 12px;

      font-weight: bold;
      color: #000000;
      line-height: 16px;
      margin-right: 20px;

      font {
        color: #ff730b;
      }

      &:first-child {
        margin-bottom: 12px;
        font-size: 14px;
        margin-right: 20px;
        font-weight: bold;
        color: #000000;
        padding-top: 15px;
      }

      button {
        font-size: 14px;

        color: #000000;
        line-height: 20px;
        background-color: transparent;
        border: solid 1px #47bd9b;

        padding: 5px 12px;
        border-radius: 5px;
        outline: none;
        color: #47bd9b;

        &.react {
          margin-right: 20px;
        }

        &.save {
          background-color: #47bd9b;
          color: $homePageSubjectColor;
          color: white;
        }
      }
    }
  }
}

.operatingOptions {
  margin: 0 auto;
  width: 1400px;
  height: 100px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px 40px;
  z-index: 9;

  .operatingOptionsLeft {
    min-height: 32px;
    display: flex;

    .choies {
      display: flex;
      align-items: center;
      margin-right: 40px;

      input {
        margin-right: 10px;
      }

      .qx {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .caozuoBox {
      display: flex;
      align-items: center;

      .caoZuoAnNiuGroup {
        .opt {
          min-width: 100px;
          height: 40px;
          background: #608bff;
          border-radius: 4px;
          line-height: 40px;
          margin-left: 10px;
          color: #fff;
          border: 0 !important;
          font-size: 16px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #ffffff;
          // div {
          //   width: 157px;
          //   height: 30px;
          //   background: #eff4ff;
          //   border-radius: 4px;
          //   &:hover {
          //     width: 157px !important;
          //     height: 30px;
          //     background: #eff4ff;
          //     border-radius: 4px;
          //     font-size: 14px;
          //     font-family: Roboto-Regular, Roboto;
          //     font-weight: 400;
          //     color: #608bff;
          //   }
          // }
        }

        button {
          display: block !important;
          height: 32px;
          margin-right: 20px;
          padding: 0 24px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    // 订单样式
    .orderDataBox1 {
      margin-left: 60px;
    }

    .orderDataBox {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      span {
        display: block;
        font-size: 14px;
        margin-left: 60px;
      }
    }
  }

  .operatingOptionsRight {
    min-width: 340px;

    .jine {
      display: flex;
      justify-content: flex-end;

      .left {
        .zongjiaBox {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 18px;

          span {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;

            .priceBox {
              font-weight: bold;
            }
          }
        }

        .zongjiaBoxMeiYuan {
          margin-top: -10px;
          display: flex;
          justify-content: flex-end;

          .huiLv {
            font-size: 14px;
            color: #999999;
            line-height: 14px;
          }

          .meiyuanPrice {
            font-size: 16px;
            font-weight: bold;
            color: #ff730b;
            line-height: 14px;
          }
        }
      }
    }

    .youhuijuan {
      // width: 140px;
      // height: 40px;
      // background: #fff1e6;
      // border-radius: 4px;
      // border: 1px solid #ff730b;
      // font-size: 14px;
      // font-family: Roboto-Regular, Roboto;
      // font-weight: 400;
      // left: 40px;
      margin-right: 64px;
      // color: #ff730b;
      margin-top: 10px;
      position: relative;

      /deep/ .el-checkbox {
        color: #ff730b;
        margin-top: 10px;
      }

      /deep/.el-checkbox__label {
        padding-left: 0px;
      }

      /deep/ .el-checkbox__inner {
        border-radius: 50px;
      }

      span {
        position: absolute;
        right: 0;
        min-width: 24px;

        transform: translateX(100%);
        top: -5px;
        font-size: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        height: 18px;
        display: flex;
        padding: 0 6px;
        justify-content: center;
        align-items: center;
        background: #ff730b;
        border-radius: 4px 4px 4px 0px;
      }
    }

    .caoZuoBtnBox {
      align-items: center;

      button {
        margin-left: 26px;
        width: 200px;
        height: 60px;
        background: #ff730b;
        border-radius: 4px;
        font-size: 16px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #ffffff;
        margin-top: -10px;
        line-height: 60px;
      }

      .baocun {
        width: 200px;
        height: 60px;
        border-radius: 4px;
        background: #fff;
        border: 1px solid #ff730b;
        color: #ff730b;
      }

      .tichu {
        width: 200px;
        height: 60px;
        background: #ff730b;
        border-radius: 4px;
        color: #fff;
      }
    }
  }
}

.theFullCost {
  display: flex;
  width: $pageWidth;
  background: rgba($color: #3a4de4, $alpha: 0.05);
  border-radius: 0px;
  border: solid 1px #e8e8e8;
  margin: 0 auto 20px;

  .fujiafuwu {
    position: relative;
  }

  >div {
    padding: 20px;

    >h2 {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    &.Con {
      width: 420px;
      font-size: 12px;

      color: #000000;
      line-height: 16px;

      >p {
        line-height: 32px;
        display: flex;
        width: 420px;

        span {
          display: inline-block;

          &:first-child {
            width: 154px;
            margin-right: 20px;
          }

          &:nth-child(2) {
            margin-right: 5px;
          }

          &.spanBody {
            width: 180px;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &.fujia {
            max-width: 120px;
          }
        }

        .detail {
          font-size: 12px;
          background-color: transparent;
          border: none;
          cursor: pointer;
          outline: none;
          color: #1e2997;

          padding: 0 10px;
          line-height: 16px;
          margin-left: 10px;
        }
      }
    }

    &.wuliufangshi {
      margin-right: 20px;

      .transportation {
        width: 360px;
        height: 30px;
        border-radius: 0px;
        border: 1px solid #b6bef7;
        margin-bottom: 10px;
        padding-left: 10px;
        background-color: white;
        position: relative;
        padding-left: 10px;
        line-height: 30px;
        position: relative;

        .transportationDown {
          position: absolute;
          top: 50%;
          transform: translate(0%, -50%) rotate(0deg);
          transition: 0.3s;
          right: 10px;
        }

        .transportationOpt {
          position: absolute;
          top: 102%;
          left: 0;
          width: 360px;
          height: 40px;
          border-radius: 0px;
          background-color: white;
          border: 1px solid rgba($color: #182280, $alpha: 0.3);
          padding: 5px 0;
          overflow: hidden;

          p {
            width: 100%;
            height: 26px;
            padding-left: 30px;
            display: flex;
            align-items: center;

            img {
              position: absolute;
              left: 13px;
            }

            &:hover {
              background-color: #e2e5ff;
              color: $homePageSubjectColor;
            }
          }
        }
      }

      >select {
        width: 180px;
        height: 30px;
        border-radius: 0px;
        border: 1px solid #b6bef7;
        margin-bottom: 10px;
        padding-left: 10px;
      }

      >span {
        display: block;
        width: 180px;
        font-size: 12px;

        color: #888888;
        line-height: 16px;
      }
    }

    &.teShuBeiZhu {
      flex: 1;

      >h2 {
        margin-top: 10px;
        line-height: unset;
      }

      >textarea {
        width: 100%;
        height: 100px;

        border-radius: 0px;
        border: solid 1px #e4e7ed;

        margin-bottom: 5px;
        padding-left: 10px;
        padding-top: 7px;
        background-color: white;
      }

      >span {
        font-size: 12px;

        color: #888888;
        line-height: 16px;
      }
    }
  }
}

.addressAdministration {
  margin: 0 auto;
  width: $pageWidth;
  background: #ffffff;
  border-radius: 0px;
  margin-bottom: 20px;
  display: flex;

  padding: 30px;

  .optOne {
    width: 314px;

    &:first-child {
      margin-right: 236px;
    }

    >p {
      display: flex;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &.oheader {
        justify-content: space-between;
        margin-bottom: 20px;

        .oselect {
          /deep/.el-input {
            width: 180px;
            height: 30px;
            // border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
          }

          /deep/.el-input__inner {
            font-size: 12px;

            color: #000000;
            line-height: 16px;
            border-radius: 0px;
            height: 100%;
            border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
          }

          /deep/.el-select__caret {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      >span {
        display: flex;
        align-items: center;

        &:first-child {
          width: 64px;
          margin-right: 10px;
          font-size: 12px;
        }
      }

      >input {
        width: 240px;
        height: 22px;
        background: #f6f6f6;
        border-radius: 0px;
        border: 1px solid #d7d7d7;
        padding-left: 10px;
      }
    }
  }
}

.checkOptionBox {
  margin-right: 3px;
}

/deep/.el-checkbox__input {
  height: 16px;
}

/deep/.cartDia {
  .el-dialog {
    // top: -80px;
    margin-bottom: 0;
    height: 800px;
  }

  .el-dialog__header {
    margin-bottom: -16px;
  }

  .el-dialog__body {
    padding: 0;
    background-color: transparent;
  }
}

.yuGuDaoShouFeiYongMingXi {
  width: 100%;

  p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    min-width: 100%;

    .title {
      text-align: right;
      display: inline-block;
      min-width: 110px;
      margin-right: 10px;
      color: #96989a;
    }

    .content {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 700;
      color: #222222;
      min-width: 100px;
      text-align: left;
      line-height: 16px;
      margin-left: 5px;
    }

    .body1 {
      display: inline-block;
      color: #ffa724;
      font-size: 12px;
    }
  }

  &.yugu {
    p {
      .title {
        min-width: 90px !important;
      }
    }
  }
}

.detailWindowBody {
  width: 420px;
  border-radius: 0px;
  background-color: white;
  padding: 20px 10px 20px 20px;
  max-height: 500px;
  overflow-y: auto;

  .detailOpt {
    h1 {
      font-size: 12px;

      line-height: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .optClass {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      span {
        display: block;
        padding: 7px 10px;
        border: 1px solid #cccccc;
        font-size: 12px;
        line-height: 16px;

        margin-right: 10px;
        cursor: pointer;
        margin-bottom: 10px;
        max-width: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.active {
          color: #ffa724;
          border: 1px solid #ffa724;
        }
      }
    }
  }

  .btnGroup {
    text-align: right;

    button {
      font-size: 14px;

      color: #000000;
      line-height: 20px;
      background-color: transparent;

      &.queRen {
        margin-left: 20px;
        color: $homePageSubjectColor;
        text-decoration: underline;
      }
    }
  }
}

.showDetail {
  // display: flex;
  // // justify-content: center;
  // align-items: center;

  height: 90px;

  margin-right: 30px;
  font-weight: normal;
  // overflow-y: auto;

  img {
    margin-top: 25px;
  }

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
    background: #ededed;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .showDetailCon {
    width: 105px;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90px;
    padding-bottom: 5px;

    .goodsDetailOpt {
      width: 100px;
      word-break: break-all;

      font-weight: normal;
    }
  }
}

.zeroInventoryGoodsDialogVisibleHeader {
  height: 64px;
  padding: 0 30px;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;

  div {
    color: #222222;
    font-size: 18px;
  }

  img {
    width: 16px;
    height: 16px;
  }
}

.zeroInventoryGoodsDialogVisibleCenter {
  display: flex;
  padding: 30px;

  img {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  div {
    color: #222222;
    margin-top: -4px;
  }
}

.zeroInventoryGoodsDialogVisibleFooter {
  justify-content: space-between;
  padding: 0 60px 30px;

  div {
    width: 120px;
    height: 40px;
    border: 1px solid #e2e2e2;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
    background-color: #fff;
    color: #222222;
    border-radius: 4px !important;
  }

  div:first-child {
    margin-right: 40px;
  }

  div:last-child {
    color: #ffffff;
    border: 1px solid #ff730b;
    background-color: #ff730b;
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.showfba {
  display: block;
  cursor: pointer;
  height: 13px;
  line-height: 13px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.priceDetail {
  min-height: 74px;
  background-color: white;
  z-index: 2;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) !important;
  border-radius: 6px !important;

  >ul {
    display: flex;
    border-radius: 0px;

    >li {
      height: 37px;
      background: #fafafa;
      width: 80px;
      text-align: center;
      height: 37px;
      line-height: 37px;
      border: solid 1px #ededed;
      margin: 0 -1px -1px 0;
      font-size: 12px;
      color: #000000;

      &.optionName {
        width: 240px;
      }
    }

    &.obody {
      li {
        background-color: white;

        &.optionName {
          background: #fafafa;
          width: 240px;
        }
      }
    }
  }
}

.couponDetail {
  color: #1e2997;
}
</style>
